import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { BASE_API_URL } from "../../../../../../common/endpoints";
import TranslationComparePanelHeader from "./translation_compare_panel_header";
import LanguageSelect from "../../../bible_navigation_panel_desktop/bible_translations_panel/language_select";
import ComparedTranslationsList from "./compared_translations_list";
import { isCombinedVerses } from "../../../../../../common/utils/functions/bible_page_functions";
import {
  BIBLE_TRANSLATIONS_LANGUAGES,
  USER_LANGUAGE,
} from "../../../../bible_page_data";

const TranslationComparePanelWrapper = styled.div`
  max-width: 236px;
`;

const TranslationComparePanelContainer = styled.div`
  max-width: 236px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
`;

const TranslationComparePanel = ({
  translation,
  panelIndex,
  bibleTranslations,
  isExpanded,
  setBibleTranslations,
  selectedVerses,
  setIsLoading,
  setComparedTranslationsList,
  toggleOpenComparedTranslationHeader,
  setComparedTranslationsData,
  setCurrentOpenTranslationIndex,
}) => {
  const [currentScreen, setCurrentScreen] = useState("translations"); //translations, languages
  const [currentBibleLanguage, setCurrentBibleLanguage] = useState(
    BIBLE_TRANSLATIONS_LANGUAGES[USER_LANGUAGE]
  );

  const translationLanguages = Object.values(BIBLE_TRANSLATIONS_LANGUAGES);

  useEffect(() => {
    if (translation?.bible_code) {
      setCurrentBibleLanguage(
        BIBLE_TRANSLATIONS_LANGUAGES[translation.bible_locale]
      );
    } else {
      setCurrentBibleLanguage(BIBLE_TRANSLATIONS_LANGUAGES[USER_LANGUAGE]);
    }
  }, [translation]);

  const onTranslationClick = useCallback(
    async (translation) => {
      const selectedVersesNumbers = selectedVerses?.verses.map(
        (verse) => verse.verse_num
      );

      try {
        const response = await axios.get(`${BASE_API_URL}/bible/verses`, {
          params: {
            bible: translation.bible_code,
            book: selectedVerses.verses[0]?.book_code,
            chapter: selectedVerses.verses?.[0].chapter_num,
          },
        });
        const data = response.data;
        const filteredVerses = data.verses.filter((verse) =>
          selectedVersesNumbers.includes(verse.verse_num)
        );

        const newComparedVerse = {
          lang: translation.bible_locale,
          bible_code: translation.bible_code,
          bible_name: translation.bible_name,
          verses: isCombinedVerses(filteredVerses, translation.bible_locale),
        };

        setComparedTranslationsData((prevState) => {
          const newState = [...prevState];
          newState[panelIndex] = newComparedVerse;
          return newState;
        });

        setComparedTranslationsList((prevState) => {
          return prevState.map((transl, index) =>
            index === panelIndex ? translation : transl
          );
        });

        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    },
    [panelIndex, translation, selectedVerses]
  );

  useEffect(() => {
    if (translation?.bible_code) {
      onTranslationClick(translation);
    }
  }, [translation]);

  return (
    <TranslationComparePanelWrapper onClick={(e) => e.stopPropagation()}>
      <TranslationComparePanelContainer>
        <TranslationComparePanelHeader
          translation={translation}
          panelIndex={panelIndex}
          setComparedTranslationsList={setComparedTranslationsList}
          setComparedTranslationsData={setComparedTranslationsData}
          setCurrentOpenTranslationIndex={setCurrentOpenTranslationIndex}
          toggleOpenComparedTranslationHeader={(e) =>
            toggleOpenComparedTranslationHeader(e)
          }
        />

        {isExpanded && (
          <>
            {currentScreen === "translations" && (
              <ComparedTranslationsList
                bibleTranslations={bibleTranslations}
                currentBibleLanguage={currentBibleLanguage}
                translation={translation}
                setCurrentScreen={setCurrentScreen}
                setBibleTranslations={setBibleTranslations}
                onTranslationClick={onTranslationClick}
              />
            )}

            {currentScreen === "languages" && (
              <LanguageSelect
                translationsLanguages={translationLanguages}
                setCurrentScreen={setCurrentScreen}
                currentBibleLanguage={currentBibleLanguage}
                setCurrentBibleLanguage={setCurrentBibleLanguage}
              />
            )}
          </>
        )}
      </TranslationComparePanelContainer>
    </TranslationComparePanelWrapper>
  );
};

export default memo(TranslationComparePanel);
