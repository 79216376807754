import React, { memo, useCallback } from "react";
import styled from "styled-components";
import RemoveIcon from "../../../../../../common/assets/icons/remove_icon";
import VerticalLine from "../../../../../../common/assets/icons/vertical_line";
import {
  MinusTranslationIcon,
  PlusTranslationIcon,
} from "../../../../../../common/assets/icons";

const TranslationCompareItemHeaderContainer = styled.div`
  position: relative;
  height: 36px;
`;

const TranslationCompareTitle = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: max-content;
  max-width: 89%;
  padding-right: 6px;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
`;

const BibleTranslationButton = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  height: 100%;
  padding-left: 12px;
  overflow: hidden;

  p {
    color: #f8d254;
    font-size: 14px;
    font-weight: 600;
    line-height: 122%;
    letter-spacing: 0.4px;
    text-transform: uppercase;
  }

  h6 {
    margin-right: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #828282;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.14px;
  }
`;

const VerticalLineContainer = styled.div`
  display: flex;
  align-items: center;
`;

const VerticalLineStyled = styled(VerticalLine)`
  width: 1px;
`;

const MinusTranslationButton = styled.div`
  display: flex;
  align-items: center;
  width: max-content;
  padding-right: 6px;
  height: 100%;

  ${VerticalLineStyled} {
    margin-right: 10px;
  }
`;

const PlusTranslationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 36px;
  min-width: 36px;
  background: #fff;
  border-radius: 15px;
  margin-left: 4px;
`;

const CloseTranslationButton = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 6px 4px 4px 4px;
`;

const RemoveIconStyled = styled(RemoveIcon)`
  width: 22px;
  height: 22px;
`;

const TranslationCompareItemHeader = ({
  translation,
  translationIndex,
  setComparedTranslationsList,
  setSelectTranslationData,
  onCloseBibleCompare,
}) => {
  const showMinusButton = translationIndex > 1;
  const showPlusButton = translationIndex > 0;

  const onClickMinusButton = useCallback(
    (event) => {
      event.stopPropagation();
      setComparedTranslationsList((prevState) =>
        prevState.filter(
          (transl) => transl.bible_code !== translation.bible_code
        )
      );
    },
    [translation, setComparedTranslationsList]
  );

  const onClickEditTranslation = useCallback(
    (event) => {
      event.stopPropagation();
      setSelectTranslationData({ translationIndex, type: "edit_translation" });
    },
    [translationIndex]
  );

  const onClickPlusButton = useCallback(
    (event) => {
      event.stopPropagation();
      setSelectTranslationData({ translationIndex, type: "new_translation" });
    },
    [translationIndex]
  );

  return (
    <TranslationCompareItemHeaderContainer>
      <TranslationCompareTitle isFirstTranslation={translationIndex === 0}>
        <ButtonsWrapper>
          <BibleTranslationButton onClick={onClickEditTranslation}>
            <p>{translation.bible_code}</p>
            <VerticalLineContainer>
              <VerticalLineStyled height="24" />
            </VerticalLineContainer>
            <h6>{translation.bible_name}</h6>
          </BibleTranslationButton>

          {showMinusButton && (
            <MinusTranslationButton onClick={onClickMinusButton}>
              <VerticalLineStyled height="24" />
              <MinusTranslationIcon />
            </MinusTranslationButton>
          )}
        </ButtonsWrapper>

        {showPlusButton && (
          <PlusTranslationButton onClick={onClickPlusButton}>
            <PlusTranslationIcon />
          </PlusTranslationButton>
        )}
      </TranslationCompareTitle>

      {translationIndex === 0 && (
        <CloseTranslationButton onClick={onCloseBibleCompare}>
          <RemoveIconStyled color="#383838" />
        </CloseTranslationButton>
      )}
    </TranslationCompareItemHeaderContainer>
  );
};

export default memo(TranslationCompareItemHeader);
