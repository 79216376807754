import React, { createContext, useContext, useState } from "react";

const BiblePageContext = createContext();

export const useBiblePageState = () => {
  const [currentTranslationData, setCurrentTranslationData] = useState([]);
  const [showStrongsCode, setShowStrongsCode] = useState(false);
  const [strongsCodeModalData, setStrongsCodeModalData] = useState(null);
  const [currentTestament, setCurrentTestament] = useState("");
  const [activeScreenMobile, setActiveScreenMobile] = useState("bible_chapter"); // bible_chapter, bible_navigation, bible_translation, bible_compare, bible_search

  return {
    currentTranslationData,
    setCurrentTranslationData,
    showStrongsCode,
    setShowStrongsCode,
    strongsCodeModalData,
    setStrongsCodeModalData,
    currentTestament,
    setCurrentTestament,
    activeScreenMobile,
    setActiveScreenMobile,
  };
};

export const BiblePageProvider = ({ children }) => {
  const {
    currentTranslationData,
    setCurrentTranslationData,
    showStrongsCode,
    setShowStrongsCode,
    strongsCodeModalData,
    setStrongsCodeModalData,
    currentTestament,
    setCurrentTestament,
    activeScreenMobile,
    setActiveScreenMobile,
  } = useBiblePageState();

  return (
    <BiblePageContext.Provider
      value={{
        currentTranslationData,
        setCurrentTranslationData,
        showStrongsCode,
        setShowStrongsCode,
        strongsCodeModalData,
        setStrongsCodeModalData,
        currentTestament,
        setCurrentTestament,
        activeScreenMobile,
        setActiveScreenMobile,
      }}
    >
      {children}
    </BiblePageContext.Provider>
  );
};

export const useBiblePageContext = () => {
  return useContext(BiblePageContext);
};
