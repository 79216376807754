import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import TranslationHeaderMobile from "../../../bible_translation_mobile/translation_header_mobile";
import LanguageSelect from "../../../../bible_page_desktop/bible_navigation_panel_desktop/bible_translations_panel/language_select";
import ComparedTranslationsList from "../../../../bible_page_desktop/bible_page_compare_desktop/translation_compare_panels/translation_compare_panel/compared_translations_list";
import {
  BIBLE_TRANSLATIONS_LANGUAGES,
  USER_LANGUAGE,
} from "../../../../bible_page_data";

const CompareTranslationSelectContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 20px);
  padding: 15px 0 20px 0;
  background: #fff;
`;

const CompareTranslationSelect = ({
  selectTranslationData,
  comparedTranslationsList,
  bibleTranslations,
  setBibleTranslations,
  setSelectTranslationData,
  setComparedTranslationsList,
}) => {
  const [currentScreen, setCurrentScreen] = useState("translations"); //translations, languages
  const [currentBibleLanguage, setCurrentBibleLanguage] = useState(
    BIBLE_TRANSLATIONS_LANGUAGES[USER_LANGUAGE]
  );

  const translationLanguages = Object.values(BIBLE_TRANSLATIONS_LANGUAGES);

  const currentTranslation =
    comparedTranslationsList?.[selectTranslationData.translationIndex];

  const onClickCloseButton = useCallback((event) => {
    event.stopPropagation();
    setSelectTranslationData({});
  }, []);

  const onTranslationClick = useCallback(
    (translation) => {
      if (selectTranslationData.type === "new_translation") {
        setComparedTranslationsList((prevState) => [...prevState, translation]);
      } else {
        setComparedTranslationsList((prevState) =>
          prevState.map((transl, index) =>
            index === selectTranslationData.translationIndex
              ? translation
              : transl
          )
        );
      }

      setSelectTranslationData({});
    },
    [selectTranslationData, setComparedTranslationsList]
  );

  return (
    <CompareTranslationSelectContainer>
      <TranslationHeaderMobile
        currentTranslation={currentTranslation}
        onClickCloseButton={onClickCloseButton}
      />

      {currentScreen === "translations" && (
        <ComparedTranslationsList
          bibleTranslations={bibleTranslations}
          currentBibleLanguage={currentBibleLanguage}
          translation={currentTranslation}
          setCurrentScreen={setCurrentScreen}
          setBibleTranslations={setBibleTranslations}
          onTranslationClick={onTranslationClick}
        />
      )}

      {currentScreen === "languages" && (
        <LanguageSelect
          translationsLanguages={translationLanguages}
          setCurrentScreen={setCurrentScreen}
          currentBibleLanguage={currentBibleLanguage}
          setCurrentBibleLanguage={setCurrentBibleLanguage}
        />
      )}
    </CompareTranslationSelectContainer>
  );
};

export default memo(CompareTranslationSelect);
