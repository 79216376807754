import React, { memo } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Verses from "./verses";

const BibleChapterContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  flex: 1;

  @media (max-width: 830px) {
    flex-direction: column;
  }
`;

const BibleChapter = ({
  selectedVerses,
  setSelectedVerses,
  currentTranslationFirst,
  currentTranslationSecond,
}) => {
  const [searchParams] = useSearchParams();

  return (
    <BibleChapterContainer>
      <Verses
        isFirstTranslation={true}
        selectedVerses={selectedVerses}
        setSelectedVerses={setSelectedVerses}
        currentTranslation={currentTranslationFirst}
      />

      {searchParams.get("2bibleId") && (
        <Verses
          isFirstTranslation={false}
          selectedVerses={selectedVerses}
          setSelectedVerses={setSelectedVerses}
          currentTranslation={currentTranslationSecond}
        />
      )}
    </BibleChapterContainer>
  );
};

export default memo(BibleChapter);
