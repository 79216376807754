import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import BiblePageCompareIcon from "../../../common/assets/icons/bible_page_icons/bible_page_compare_icon";
import LeftArrowIcon from "../../../common/assets/icons/left_arrow_icon";
import BiblePageSidebarCopy from "./bible_page_sidebar_copy";
import BiblePageSidebarShare from "./bible_page_sidebar_share";
import useIsMobile from "../../../common/hooks/use_is_mobile";
import { useBiblePageContext } from "../bible_page_context";

export const BiblePageSidebarItem = styled.div`
  padding: 5px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    scale: 1.15;
  }

  p {
    display: none;
  }

  @media (max-width: 744px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    min-height: 70px;
    max-height: 70px;

    p {
      display: inline-block;
      color: #cacaca;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

const BiblePageSidebarContainer = styled.div`
  position: absolute;
  ${({ isMobile }) => !isMobile && "top: 50%;"};
  transform: translateY(-55%);
  left: ${({ isOpen }) => (isOpen ? "0" : "-43px")};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 71px;
  height: 218px;
  padding: 23px 0 23px 27px;
  border-radius: 0px 15px 15px 0px;
  background: #fff;
  transition: all 0.3s ease;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  span {
    display: ${({ isOpen }) => (isOpen ? "none" : "flex")};
  }

  @media (max-width: 1200px) {
    left: ${({ isOpen }) => (isOpen ? "0" : "-43px")};
  }

  @media (max-width: 744px) {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: none;
    transform: translateX(-50%);
    width: 100%;
    height: 96px;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 45px;
    z-index: 99999;
    border-radius: 0;
    box-shadow: none;
  }

  @media (max-width: 412px) {
    height: 103px;
    border-radius: 25px 25px 0 0;
  }

  @media (max-width: 380px) {
    padding: 14px 35px;
  }
`;

const BiblePageSidebarButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  @media (max-width: 744px) {
    width: 100%;
    max-width: 440px;
    height: 100%;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BiblePageSidebarArrow = styled.span`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  left: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 100%;
  transform: rotate(180deg);
  cursor: pointer;
`;

const BiblePageSidebar = ({
  bibleTranslations,
  selectedVerses,
  activeScreenDesktop,
  selectedSearchVerse,
  setActiveScreenDesktop,
  setSelectedVerses,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { activeScreenMobile, setActiveScreenMobile, strongsCodeModalData } =
    useBiblePageContext();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const isTablet = useIsMobile(1200);
  const isMobile = useIsMobile(744);

  const selectBible = searchParams.get("selectBible");
  const bibleId = searchParams.get("bibleId");

  const showSidebar =
    (!!selectBible || selectedSearchVerse?._id) && !strongsCodeModalData;

  const currentBibleScreen = isMobile
    ? activeScreenMobile
    : activeScreenDesktop;
  const setCurrentBibleScreen = isMobile
    ? setActiveScreenMobile
    : setActiveScreenDesktop;

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  const openVersesCompare = useCallback(() => {
    if (currentBibleScreen === "bible_chapter") {
      setCurrentBibleScreen("bible_compare");
      setSearchParams((prev) => {
        prev.set("bibleId", selectBible);
        prev.delete("2bibleId");
        return prev;
      });
    }

    if (currentBibleScreen === "bible_search") {
      setCurrentBibleScreen("bible_compare");
      setSearchParams((prev) => {
        prev.set("selectBible", bibleId);
        prev.set("selectVerses", selectedSearchVerse.verse_num);
        prev.set("bookId", selectedSearchVerse.book_code);
        prev.set("chapterId", selectedSearchVerse.chapter_num);
        return prev;
      });
      setSelectedVerses({ bible: bibleId, verses: [selectedSearchVerse] });
    }
  }, [selectBible, bibleId, selectedSearchVerse, setSearchParams]);

  useEffect(() => {
    if ((isTablet && !selectedVerses.bible) || !selectedSearchVerse?._id) {
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
    }

    if (isMobile) {
      setIsSidebarOpen(true);
    }
  }, [isTablet, isMobile, selectedVerses.bible, selectedSearchVerse]);

  useEffect(() => {
    if (isTablet && (!selectedVerses.bible || !selectedSearchVerse?._id)) {
      setIsSidebarOpen(false);
    } else {
      setIsSidebarOpen(true);
    }

    if (isMobile) {
      setIsSidebarOpen(true);
    }
  }, [isTablet, isMobile, selectedVerses.bible, selectedSearchVerse]);

  return (
    <>
      {showSidebar && (
        <BiblePageSidebarContainer isOpen={isSidebarOpen} isMobile={isMobile}>
          {isSidebarOpen ? (
            <BiblePageSidebarButtons>
              <BiblePageSidebarShare
                selectedSearchVerse={selectedSearchVerse}
                currentBibleScreen={currentBibleScreen}
              />
              <BiblePageSidebarCopy
                bibleTranslations={bibleTranslations}
                selectedVerses={selectedVerses}
                selectedSearchVerse={selectedSearchVerse}
                currentBibleScreen={currentBibleScreen}
              />

              <BiblePageSidebarItem onClick={openVersesCompare}>
                <BiblePageCompareIcon />
                <p>Сравнить</p>
              </BiblePageSidebarItem>
            </BiblePageSidebarButtons>
          ) : (
            <BiblePageSidebarArrow onClick={toggleSidebar}>
              <LeftArrowIcon color={"rgb(130, 130, 130)"} />
            </BiblePageSidebarArrow>
          )}
        </BiblePageSidebarContainer>
      )}
    </>
  );
};

export default memo(BiblePageSidebar);
