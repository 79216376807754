import React, { createContext, useContext, useState } from "react";

const AuthPageContext = createContext();

export const useAuthPageState = () => {
  const [currentAuthScreen, setCurrentAuthScreen] = useState(""); // registration_code, password_recovery_code, new_password
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [redirectPath, setRedirectPath] = useState("");
  const [verificationToken, setVerificationToken] = useState("");

  return {
    email,
    setEmail,
    name,
    setName,
    secondName,
    setSecondName,
    password,
    setPassword,
    country,
    setCountry,
    city,
    setCity,
    confirmPassword,
    setConfirmPassword,
    currentAuthScreen,
    setCurrentAuthScreen,
    redirectPath,
    setRedirectPath,
    verificationToken,
    setVerificationToken,
  };
};

export const AuthPageProvider = ({ children }) => {
  const {
    email,
    setEmail,
    name,
    setName,
    secondName,
    setSecondName,
    password,
    setPassword,
    country,
    setCountry,
    city,
    setCity,
    confirmPassword,
    setConfirmPassword,
    currentAuthScreen,
    setCurrentAuthScreen,
    redirectPath,
    setRedirectPath,
    verificationToken,
    setVerificationToken,
  } = useAuthPageState();

  return (
    <AuthPageContext.Provider
      value={{
        email,
        setEmail,
        name,
        setName,
        secondName,
        setSecondName,
        password,
        setPassword,
        country,
        setCountry,
        city,
        setCity,
        confirmPassword,
        setConfirmPassword,
        currentAuthScreen,
        setCurrentAuthScreen,
        redirectPath,
        setRedirectPath,
        verificationToken,
        setVerificationToken,
      }}
    >
      {children}
    </AuthPageContext.Provider>
  );
};

export const useAuthPageContext = () => {
  return useContext(AuthPageContext);
};
