import React, { useCallback, useRef, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import styled from "styled-components";
import { APPLICATION_ROUTES } from "../../../utils/routes";
import HeaderLanguageSelector from "./header_language_selector";
import { HeaderMoreIcon as DefaultMoreIcon } from "../../../assets/icons";
import { UserIcon } from "./header_styled_components";
import { useUserDataContext } from "../../../../user_data_context";
import UserImage from "../../user_image";
import { useNavigate } from "react-router-dom";

export const MoreIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 53px;
  font-size: 14px;
  line-height: 26px;
  cursor: pointer;
  user-select: none;
  color: ${({ isMoreMenuOpened }) =>
    isMoreMenuOpened ? "#1e1e1e" : " #8e8e8e"};

  ${({ isMoreMenuOpened }) =>
    isMoreMenuOpened &&
    `svg {
    fill: #1e1e1e;
  }`}

  @media (max-width: 1024px) {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }

  @media (max-width: 470px) {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`;

const MoreIcon = styled(DefaultMoreIcon)`
  fill: #8e8e8e;
`;

const MoreMenuWrapper = styled.div`
  position: relative;
  display: none;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const MoreMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  background: #fff;
  padding: 24px 20px;
  bottom: calc(100% + 35px);
  left: -6px;
  border-radius: 15px;

  @media (max-width: 1024px) {
    font-size: 24px;
    border-radius: 25px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  }
`;

const MoreMenuItem = styled.div`
  border-bottom: 1px solid rgb(202 202 202 / 52%);
  padding-bottom: 20px;
  cursor: pointer;

  &:last-child {
    border: 0;
    padding-bottom: 0;
  }
`;

const HeaderMoreMenu = () => {
  const moreMenuRef = useRef();
  const { userData } = useUserDataContext();
  const navigate = useNavigate();

  const [isMoreMenuOpened, setIsMoreMenuOpened] = useState(false);

  const moreButtonClickHandler = useCallback(() => {
    setIsMoreMenuOpened((prevState) => !prevState);
  }, []);

  useOnclickOutside(() => setIsMoreMenuOpened(false), { refs: [moreMenuRef] });

  const onClickUserProfile = useCallback(
    (event) => {
      event.stopPropagation();

      const navigatePath = !userData._id
        ? APPLICATION_ROUTES.AUTH
        : APPLICATION_ROUTES.PROFILE;

      setIsMoreMenuOpened(false);
      navigate(navigatePath);
    },
    [userData]
  );

  return (
    <MoreMenuWrapper ref={moreMenuRef}>
      <MoreIconContainer
        isMoreMenuOpened={isMoreMenuOpened}
        onClick={moreButtonClickHandler}
      >
        <MoreIcon />
        Больше
      </MoreIconContainer>

      {isMoreMenuOpened && (
        <MoreMenuContainer>
          <MoreMenuItem onClick={onClickUserProfile}>
            {!userData._id ? (
              <UserIcon />
            ) : (
              <UserImage userData={userData} size={"28px"} isButton={true} />
            )}
          </MoreMenuItem>

          {/* <MoreMenuItem>
            <HeaderLanguageSelector />
          </MoreMenuItem> */}
        </MoreMenuContainer>
      )}
    </MoreMenuWrapper>
  );
};
export default HeaderMoreMenu;
