React.js;
import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { BASE_API_URL } from "../../../../endpoints";
import axios from "axios";
import styled from "styled-components";
import ConcordanceVerse from "./concordance_verse";
import SpinnerLoader from "../../../loaders/spinner_loader";
import ConcordanceChapter from "./concordance_chapter";

const BibleStrongConcordanceVerses = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding-right: 4px;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const StrongsCodeRefsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;
  flex: 1;
  width: 100%;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 86%;
  width: 100%;
`;

const HighlightText = styled.span`
  border-radius: 4px;
  background: rgba(248, 210, 84, 0.5);
  padding: 0 1px;
`;

const bibleVersesWithoutTags = (verse) => {
  return verse
    .replace(/<n>.*?<\/n>/g, "")
    .replace(/<J>(.*?)<\/J>/g, "$1")
    .replace(/<R>(.*?)<\/R>/g, "$1")
    .replace(/<br\/?>/g, "");
};

export const highlightWordBeforeTag = (unformattedText, targetCode) => {
  const text = bibleVersesWithoutTags(unformattedText);

  const regex = new RegExp(`(\\S+)\\s*<S>${targetCode}</S>`, "g");
  let match;
  const parts = [];
  let lastIndex = 0;

  while ((match = regex.exec(text)) !== null) {
    const word = match[1];
    const matchStart = match.index;
    const matchEnd = regex.lastIndex;

    if (lastIndex < matchStart) {
      parts.push(text.slice(lastIndex, matchStart));
    }

    parts.push(
      <HighlightText key={matchStart}>
        {word.replace(/<S>.*?<\/S>/g, "")}
      </HighlightText>
    );

    lastIndex = matchEnd;
  }

  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return parts.map((part, index) =>
    typeof part === "string" ? part.replace(/<S>\d+<\/S>/g, "") : part
  );
};

const BibleStrongConcordance = ({
  currentTranslation,
  currentCodeStrong,
  concordanceRefs,
  currentScreen,
  concordanceSelectedVerse,
  setConcordanceSelectedVerse,
  setCurrentScreen,
  setOpen,
  setShowStrongsCode,
}) => {
  const selectedVerseRef = useRef(null);

  const [currentRefsVerses, setCurrentRefsVerses] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  const countPlacesOnPage = 15;

  const fetchRefsVerses = useCallback(async () => {
    if (isFetching || offset >= concordanceRefs.length) return;

    setIsFetching(true);

    const refsToFetch = concordanceRefs.slice(
      offset,
      offset + countPlacesOnPage
    );

    if (refsToFetch.length > 0) {
      try {
        const response = await axios.post(
          `${BASE_API_URL}/bible/verses/full-text`,
          {
            bible_code: currentTranslation.bible_code,
            refs: refsToFetch.join(", "),
          }
        );

        const verses = response.data.verses;

        setCurrentRefsVerses((prev) => [...prev, ...verses]);
        setOffset((prevOffset) => prevOffset + countPlacesOnPage);
      } catch (error) {
        console.error(error);
      } finally {
        setIsFetching(false);
      }
    }
  }, [currentTranslation, offset, concordanceRefs, isFetching]);

  useEffect(() => {
    if (concordanceRefs.length > 0 && currentRefsVerses.length === 0) {
      fetchRefsVerses();
    }
  }, [concordanceRefs, fetchRefsVerses]);

  useEffect(() => {
    return () => {
      setConcordanceSelectedVerse({});
    };
  }, []);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;

    if (!isFetching && scrollTop + clientHeight >= scrollHeight - 5) {
      fetchRefsVerses();
    }
  };

  useEffect(() => {
    if (
      currentScreen === "concordance" &&
      concordanceSelectedVerse._id &&
      selectedVerseRef.current
    ) {
      selectedVerseRef.current.scrollIntoView({
        behavior: "auto",
        block: "center",
      });
    }
  }, [currentScreen, concordanceSelectedVerse]);

  return (
    <>
      {currentScreen === "concordance" ? (
        <BibleStrongConcordanceVerses onScroll={handleScroll}>
          {!currentRefsVerses.length ? (
            <LoaderContainer>
              <SpinnerLoader />
            </LoaderContainer>
          ) : (
            <StrongsCodeRefsContainer>
              {currentRefsVerses.map((verse) => {
                const isSelected = verse._id === concordanceSelectedVerse._id;

                return (
                  <ConcordanceVerse
                    key={verse._id}
                    verse={verse}
                    ref={isSelected ? selectedVerseRef : null}
                    setCurrentScreen={setCurrentScreen}
                    currentCodeStrong={currentCodeStrong}
                    currentTranslation={currentTranslation}
                    setConcordanceSelectedVerse={setConcordanceSelectedVerse}
                  />
                );
              })}
            </StrongsCodeRefsContainer>
          )}
        </BibleStrongConcordanceVerses>
      ) : (
        <ConcordanceChapter
          bibleVerse={concordanceSelectedVerse}
          currentTranslation={currentTranslation}
          currentCodeStrong={currentCodeStrong}
          setOpen={setOpen}
          setShowStrongsCode={setShowStrongsCode}
        />
      )}
    </>
  );
};

export default memo(BibleStrongConcordance);
