import React from "react";
import styled from "styled-components";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";
import { GoogleIcon } from "../../../common/assets/icons";
import { useAuthPageContext } from "../auth_page_context";

export const AuthGoogleButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background: #fff;
  border-radius: 10px;
  height: 54px;
  width: 100%;
  border: none;
  margin-top: 8px;

  color: #1e1e1e;
  font-size: 20px;
  font-weight: 600;
  line-height: 140%;
  transition: 0.2s;
  user-select: none;
  cursor: pointer;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const getOAuthGmailUrl = (redirectPath) => {
  const GM_AUTH_URI = "https://accounts.google.com/o/oauth2/auth";
  const GM_CLIENT_ID =
    "236078151960-1q1mmed4s9pdmuhq4uu0hj7k03oms2ku.apps.googleusercontent.com";
  const GM_SCOPES = [
    "https://www.googleapis.com/auth/userinfo.email",
    "https://www.googleapis.com/auth/userinfo.profile",
  ];

  const GM_REDIRECT_URI =
    process.env.REACT_APP_BASE_URL + APPLICATION_ROUTES.OAUTH_GMAIL;

  const params = {
    redirect_uri: GM_REDIRECT_URI,
    response_type: "code",
    client_id: GM_CLIENT_ID,
    scope: GM_SCOPES.join(" "),
    state: JSON.stringify({ redirectPath }),
  };

  const url = new URL(GM_AUTH_URI);
  url.search = new URLSearchParams(params).toString();

  return url;
};

const OAuthGoogleButton = ({ buttonText, redirectPath }) => {
  const link = getOAuthGmailUrl(redirectPath)?.href;

  return (
    <AuthGoogleButton href={link}>
      <GoogleIcon /> {buttonText}
    </AuthGoogleButton>
  );
};

export default OAuthGoogleButton;
