import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { bibleVersesWithoutTags } from "../../../utils/functions/bible_page_functions";
import { BASE_API_URL } from "../../../endpoints";
import SpinnerLoader from "../../loaders/spinner_loader";
import BibleVerse from "./bible_verse";
import VerticalLine from "../../../assets/icons/vertical_line";
import { BIBLE_BOOK_NAMES_DATA } from "../../../../pages/bible/bible_page_data";
import { useNavigate } from "react-router-dom";
import RemoveIcon from "../../../assets/icons/remove_icon";

const BibleChapterModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const BibleChapterModalWindowContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  width: 500px;
  height: 670px;
  padding: 18px;
  border-radius: 25px;
  background: #eaeaea;

  @media (max-width: 774px) {
    height: 100%;
    width: 100%;
    border-radius: 0px;
    padding: 10px;
  }
`;

const BibleChapterContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 16px;
  width: 100%;
  height: 100%;
  padding: 12px 1px 0 12px;
  border-radius: 15px;
  border: 2px solid var(--solid-colors-white, #fff);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
`;

const BibleChapterAddressContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 10px;
  width: 215px;
  height: 24px;
  min-height: 24px;
  background: #fff;
  border-radius: 15px;
  cursor: pointer;

  &: hover {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: 0.2s;
  }
`;

const BibleTranslationVersion = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

const BibleChapterAddress = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  line-height: 140%;
  color: #1e1e1e;
  letter-spacing: -0.1px;
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 13px;
`;

const BibleVerses = styled.div`
  padding-bottom: 50px;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 86%;
  width: 100%;
`;

const CloseTranslationButton = styled.div`
  display: none;

  @media (max-width: 774px) {
    position: absolute;
    top: 20px;
    right: 25px;
    display: block;
    padding: 10px;
  }
`;

const RemoveIconStyled = styled(RemoveIcon)`
  width: 22px;
  height: 22px;
`;

const BibleChapterModalWindow = ({
  bibleModalData,
  setOpen,
  inComponent = "",
  bibleLocale = "ru",
  setBibleActiveSection,
  setBibleVersesData = () => {},
  setOpenBibleModalWindow = () => {},
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [chapterData, setChapterData] = useState([]);

  const abbreviation = chapterData?.[0]?.bible_code.toUpperCase();
  const bibleChapterAddress =
    BIBLE_BOOK_NAMES_DATA?.[bibleLocale]?.[bibleModalData?.bookId]?.name +
    " " +
    bibleModalData?.chapterId;

  const chapterFetch = useCallback(async ({ bibleId, bookId, chapterId }) => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${BASE_API_URL}/bible/verses?bible=${bibleId}&book=${bookId}&chapter=${chapterId}`
      );
      const parsedChapter = response.data.verses.map((verse) => ({
        ...verse,
        verse_text: bibleVersesWithoutTags(verse.verse_text),
      }));

      setChapterData(parsedChapter);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (bibleModalData.bibleId) {
      chapterFetch(bibleModalData);
    }
  }, [bibleModalData]);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpen({});
  }, []);

  const onClickChapterAddress = useCallback(
    (event) => {
      event.stopPropagation();
      const { bibleId, bookId, chapterId, selectedVerses } = bibleModalData;

      if (inComponent === "bible_search") {
        navigate(
          `/bible/?bibleId=${bibleId}&bookId=${bookId}&chapterId=${chapterId}&selectVerses=${selectedVerses}&selectBible=${bibleId}`
        );
        setBibleActiveSection("bible_chapter");
      } else {
        const bibleVersesData = {
          versesData: selectedVerses
            .split(",")
            .map((verse) => ({ verse_num: +verse })),
          versesParams: {
            bibleId: bibleId,
            bookId: bookId,
            chapterId: chapterId,
          },
        };
        setBibleVersesData(bibleVersesData);
        setOpenBibleModalWindow(true);
        setOpen({});
      }
    },
    [bibleModalData, inComponent]
  );

  return (
    <BibleChapterModalWindowWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleClose}
    >
      <BibleChapterModalWindowContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <BibleChapterContainer>
          <BibleChapterAddressContainer onClick={onClickChapterAddress}>
            <BibleTranslationVersion>{abbreviation}</BibleTranslationVersion>
            <VerticalLineStyled />
            <BibleChapterAddress>{bibleChapterAddress}</BibleChapterAddress>
          </BibleChapterAddressContainer>

          {!isLoading ? (
            <BibleVerses>
              {chapterData.map((verse) => (
                <BibleVerse
                  key={verse._id}
                  verse={verse}
                  inComponent={inComponent}
                  selectedVerses={bibleModalData.selectedVerses}
                  searchWords={bibleModalData.searchWord}
                />
              ))}
            </BibleVerses>
          ) : (
            <LoaderContainer>
              <SpinnerLoader />
            </LoaderContainer>
          )}
        </BibleChapterContainer>

        <CloseTranslationButton onClick={handleClose}>
          <RemoveIconStyled color="#383838" />
        </CloseTranslationButton>
      </BibleChapterModalWindowContainer>
    </BibleChapterModalWindowWrapper>
  );
};

export default memo(BibleChapterModalWindow);
