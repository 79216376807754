import React, { memo } from "react";
import styled from "styled-components";
import BookListNavigation from "../../bible_page_desktop/bible_navigation_panel_desktop/book_list_navigation";

const BibleNavigationMobileContainer = styled.div`
  background: #eaeaea;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 25px;
`;

const BibleNavigationMobile = ({ traslationLocale, bibleId }) => {
  const currentTranslation = {
    bible_locale: traslationLocale,
    bible_code: bibleId,
  };

  return (
    <BibleNavigationMobileContainer>
      <BookListNavigation currentTranslation={currentTranslation} />
    </BibleNavigationMobileContainer>
  );
};

export default memo(BibleNavigationMobile);
