import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../../../common/assets/icons/vertical_line";

const ModalWindowMainButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #5172ea;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  cursor: default;
  user-select: none;

  ${({ isDisableConfirmButton }) =>
    !isDisableConfirmButton &&
    `
  &:hover {
    font-size: 18px;
    color: #5172ea;
    transition: 0.2s;
    width: 53%;
    cursor: pointer;
  }
`}
`;

const ConfirmButton = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
`;

const CancelButton = styled(ButtonStyles)`
  color: ${({ isHoverOnConfirm }) =>
    isHoverOnConfirm ? "rgba(81, 114, 234, 0.4)" : "#5172ea"};
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
`;

const ModalWindowMainButtons = ({
  handleClose,
  onClickConfirmButton,
  isDisableConfirmButton,
}) => {
  const [isHoverOnConfirm, setIsHoverOnConfirm] = useState(false);

  const toggleHoverOnConfirmButton = useCallback(() => {
    if (!isDisableConfirmButton) {
      setIsHoverOnConfirm((prevState) => !prevState);
    }
  }, [isDisableConfirmButton]);

  return (
    <ModalWindowMainButtonsContainer>
      <ConfirmButton
        onMouseEnter={toggleHoverOnConfirmButton}
        onMouseLeave={toggleHoverOnConfirmButton}
        isDisableConfirmButton={isDisableConfirmButton}
        onClick={onClickConfirmButton}
      >
        Подтвердить
      </ConfirmButton>
      <VerticalLineStyled color={"#5172EA"} />
      <CancelButton isHoverOnConfirm={isHoverOnConfirm} onClick={handleClose}>
        Отмена
      </CancelButton>
    </ModalWindowMainButtonsContainer>
  );
};

export default memo(ModalWindowMainButtons);
