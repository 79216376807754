import React, { memo, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SearchVerse from "./search_verse";
import VerticalLine from "../../../../assets/icons/vertical_line";
import SpinnerLoader from "../../../loaders/spinner_loader";
import BackToBibleChapterButton from "../../../../../pages/bible/back_to_bible_chapter_button";

const BibleModalSearchResultsResultsContainer = styled.div`
  flex: 1;
  padding: 15px 0 20px 20px;
  max-height: calc(100vh - 65px);
  overflow: hidden;
  border-radius: 15px;
  border: 2px solid #fff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
`;

const BibleModalSearchResultsInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const BibleAndTestamentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
  min-width: 160px;
  width: max-content;
  height: 24px;
  min-height: 24px;
  background: #fff;
  border-radius: 15px;
`;

const BibleSearchAbbreviation = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

const BibleSearchTestament = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
  line-height: 120%;
  color: #1e1e1e;
  letter-spacing: -0.1px;
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 13px;
`;

const BibleModalSearchResultsResultCount = styled.div`
  display: flex;
  align-items: center;
  padding-right: 22px;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: -0.1px;
  color: #1e1e1e;
`;

const SearchVersesResults = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  padding-right: 17px;
  max-height: calc(100% - 30px);
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const EmptySearchResult = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  padding: 0 50px 0 40px;
  font-size: 16px;
  line-height: 140%;
  color: #383838;
  text-align: center;
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 40px);
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const BibleModalSearchResults = ({
  searchIsLoading,
  bibleModalSearchResults,
  currentTranslation,
  setBibleModalSearchResults,
  setActiveScreenDesktop,
  setBibleModalSearchInput,
  setCurrentTranslationParams,
  setSelectedVerses,
}) => {
  const searchVersesResultsRef = useRef(null);
  const [visibleResultsCount, setVisibleResultsCount] = useState(20);

  const totalResults = bibleModalSearchResults?.searchResults?.length || 0;
  const visibleSearchResults =
    bibleModalSearchResults?.searchResults?.slice(0, visibleResultsCount) || [];

  const searchTestament =
    bibleModalSearchResults?.searchTestament === "ot"
      ? "Ветхий Завет"
      : bibleModalSearchResults?.searchTestament === "nt"
      ? "Новый Завет"
      : "Вся Библия";

  const bibleId = bibleModalSearchResults?.searchBible;

  const testamentForEmptyResult =
    bibleModalSearchResults?.searchTestament === "ot"
      ? "Ветхом Завете"
      : bibleModalSearchResults?.searchTestament === "nt"
      ? "Новом Завете"
      : "";

  useEffect(() => {
    return () => {
      setBibleModalSearchResults({});
      setBibleModalSearchInput("");
    };
  }, []);

  useEffect(() => {
    if (searchVersesResultsRef.current) {
      searchVersesResultsRef.current.scrollTop = 0;
    }
  }, [bibleModalSearchResults?.searchResults]);

  useEffect(() => {
    setVisibleResultsCount(20);
  }, [bibleModalSearchResults?.searchResults]);

  useEffect(() => {
    setSelectedVerses([]);
  }, []);

  useEffect(() => {
    const scrollContainer = searchVersesResultsRef.current;
    const handleScroll = () => {
      if (scrollContainer) {
        const scrolledHeight =
          scrollContainer.scrollTop + scrollContainer.clientHeight;
        const totalHeight = scrollContainer.scrollHeight;
        if (scrolledHeight >= totalHeight) {
          const newVisibleResults = visibleResultsCount + 20;
          if (newVisibleResults <= totalResults) {
            setVisibleResultsCount(newVisibleResults);
          }
        }
      }
    };
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [visibleResultsCount, totalResults]);

  return (
    <BibleModalSearchResultsResultsContainer>
      {!searchIsLoading && (
        <BibleModalSearchResultsInfoContainer>
          <Wrapper>
            <BackToBibleChapterButton
              setActiveScreenDesktop={setActiveScreenDesktop}
              navigatePath={"bible_modal_chapter"}
            />

            <BibleAndTestamentContainer>
              <BibleSearchAbbreviation>{bibleId}</BibleSearchAbbreviation>
              <VerticalLineStyled />
              <BibleSearchTestament>{searchTestament}</BibleSearchTestament>
            </BibleAndTestamentContainer>
          </Wrapper>

          <BibleModalSearchResultsResultCount>
            Найдено стихов: {bibleModalSearchResults?.searchResults?.length}
          </BibleModalSearchResultsResultCount>
        </BibleModalSearchResultsInfoContainer>
      )}

      {!searchIsLoading && !bibleModalSearchResults?.searchResults?.length && (
        <EmptySearchResult>
          <p>
            По запросу <b>«{bibleModalSearchResults?.searchWord}»</b>
            {testamentForEmptyResult && <> в {testamentForEmptyResult}</>}{" "}
            ничего не найдено
          </p>
        </EmptySearchResult>
      )}

      {!searchIsLoading ? (
        <SearchVersesResults ref={searchVersesResultsRef}>
          {visibleSearchResults?.map((verse) => (
            <SearchVerse
              key={verse._id}
              verse={verse}
              bibleSearchWord={bibleModalSearchResults.searchWord.trim()}
              currentTranslation={currentTranslation}
              setActiveScreenDesktop={setActiveScreenDesktop}
              setCurrentTranslationParams={setCurrentTranslationParams}
              setSelectedVerses={setSelectedVerses}
            />
          ))}
        </SearchVersesResults>
      ) : (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      )}
    </BibleModalSearchResultsResultsContainer>
  );
};

export default memo(BibleModalSearchResults);
