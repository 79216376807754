import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import ComparedTranslationPanels from "./translation_compare_panels";
import BibleCompareVerses from "./bible_compare_verses";
import { USER_LANGUAGE } from "../../bible_page_data";

const BibleCompareContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
`;

const BibleCompareDesktop = ({
  selectedVerses,
  bibleTranslations,
  setActiveScreenDesktop,
  setBibleTranslations,
  isLoading,
  setIsLoading,
}) => {
  const [comparedTranslationsList, setComparedTranslationsList] = useState([]);
  const [comparedTranslationsData, setComparedTranslationsData] = useState([]);

  useEffect(() => {
    const filteredBibleTranslations = bibleTranslations.filter(
      (translation) =>
        translation.bible_locale === USER_LANGUAGE || translation.isFavorite
    );
    filteredBibleTranslations?.sort((a, b) => b.isFavorite - a.isFavorite);
    setComparedTranslationsList(filteredBibleTranslations);

    setIsLoading(true);

    return () => {
      setComparedTranslationsList([]);
      setComparedTranslationsData([]);
    };
  }, []);

  return (
    <BibleCompareContainer>
      <BibleCompareVerses
        comparedTranslationsData={comparedTranslationsData}
        isLoading={isLoading}
        setActiveScreenDesktop={setActiveScreenDesktop}
      />

      <ComparedTranslationPanels
        bibleTranslations={bibleTranslations}
        comparedTranslationsData={comparedTranslationsData}
        selectedVerses={selectedVerses}
        setIsLoading={setIsLoading}
        comparedTranslationsList={comparedTranslationsList}
        setBibleTranslations={setBibleTranslations}
        setComparedTranslationsList={setComparedTranslationsList}
        setComparedTranslationsData={setComparedTranslationsData}
      />
    </BibleCompareContainer>
  );
};

export default memo(BibleCompareDesktop);
