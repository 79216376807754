import React, { memo, useCallback, useEffect, useRef } from "react";
import Verse from "../../../bible_page_desktop/bible_chapter_desktop/verse";
import { useAppContext } from "../../../../../app_context";
import styled from "styled-components";
import VersesLoader from "../../../bible_page_desktop/bible_chapter_desktop/verses_loader";
import { useBibleVersesHeightsContext } from "../../../bible_page_desktop/bible_chapter_desktop/bible_verses_heights_context";
import { useSearchParams } from "react-router-dom";

const VersesContainer = styled.div`
  height: calc(100% - 54px);
  padding-bottom: 50px;
  padding-left: 10px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const VerseError = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BibleVersesMobile = ({
  isLoading,
  versesError,
  chapterData,
  isFirstTranslation,
  selectedVerses,
  setSelectedVerses,
}) => {
  const { bibleFontSettings } = useAppContext();
  const {
    scrollTop,
    handleScroll,
    setVerseHeightsFirstTransl,
    setVerseHeightsSecondTransl,
    mergedHeights,
    verseHeightsFirstTransl,
    verseHeightsSecondTransl,
  } = useBibleVersesHeightsContext();
  const versesRef = useRef(null);

  const [searchParams] = useSearchParams();
  const selectVerses = searchParams.get("selectVerses");
  const selectBible = searchParams.get("selectBible");

  useEffect(() => {
    if (selectVerses && selectBible) {
      const selectedVersesNums = selectVerses.split(",").map(Number).sort();
      const selectedVersesObjs = chapterData.filter((verse) =>
        selectedVersesNums.includes(verse.verse_num)
      );
      if (chapterData?.[0]?.bible_code === selectBible) {
        setSelectedVerses({ bible: selectBible, verses: selectedVersesObjs });
      }
    } else {
      setSelectedVerses({});
    }
  }, [selectBible, selectVerses, chapterData]);

  useEffect(() => {
    if (versesRef.current) {
      versesRef.current.scrollTop = scrollTop;
    }
  }, [scrollTop]);

  const onScroll = useCallback(() => {
    if (versesRef.current) {
      handleScroll(versesRef.current.scrollTop);
    }
  }, [versesRef.current]);

  return (
    <>
      <VersesContainer
        ref={versesRef}
        size={bibleFontSettings?.fontSize}
        font={bibleFontSettings?.fontVariant}
        onScroll={onScroll}
      >
        {isLoading ? (
          <VersesLoader bibleFontSize={bibleFontSettings?.fontSize} />
        ) : (
          <div>
            <VerseError>{versesError}</VerseError>

            {chapterData?.map((verse, index) => (
              <Verse
                key={verse._id}
                verseIndex={index}
                verse={verse}
                versesRef={versesRef}
                bibleFontSettings={bibleFontSettings}
                selectedVerses={selectedVerses}
                blockHeight={mergedHeights[index]}
                setVerseHeights={
                  isFirstTranslation
                    ? setVerseHeightsFirstTransl
                    : setVerseHeightsSecondTransl
                }
                isFirstTranslation={isFirstTranslation}
                verseHeightsFirstTransl={verseHeightsFirstTransl}
                verseHeightsSecondTransl={verseHeightsSecondTransl}
              />
            ))}
          </div>
        )}
      </VersesContainer>
    </>
  );
};

export default memo(BibleVersesMobile);
