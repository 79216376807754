import { ToastContainer } from "react-toastify";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";

export const ToastNotification = styled(ToastContainer)`
  &&&.Toastify__toast-container {
    top: 170px;
    left: 30px;
    padding: 0;
    width: max-content;
    background: none;
    z-index: 999999;

    @media (max-width: 1024px) {
      top: 80px;
      left: 15px;
    }
  }

  .Toastify__toast {
    padding: 5px 10px 5px;
    margin: 0;
    width: max-content;
    height: max-content;
    background: none;
    box-shadow: none;
  }

  .Toastify__toast-body {
    margin: 0 auto;
    text-align: center;
    padding: 0;
    height: 30px;
    width: 124px;
    font-size: 10px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.12);
  }
`;
