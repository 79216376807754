import React, { useCallback } from "react";
import styled from "styled-components";
import {
  MinusTranslationIcon,
  PlusTranslationIcon,
} from "../../../../../../common/assets/icons";
import VerticalLine from "../../../../../../common/assets/icons/vertical_line";

const ComparedTranslationHeaderContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 30px;
  cursor: pointer;
`;

const HeaderInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding-left: 14px;
  height: 100%;
  flex: 1;
  transition: 0.1s;
  overflow: hidden;
  cursor: pointer;
`;

const SelectedTranslationAbbreviation = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 133.333% */
  white-space: nowrap;
  letter-spacing: 0.4px;
`;

const SelectedTranslationText = styled.div`
  color: #828282;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: -0.2px;
`;

const TranslationHeaderIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 100%;
  padding-right: 7px;
`;

const TranslationHeaderIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;

  &:hover {
    scale: 1.1;
  }
`;

const TranslationComparePanelHeader = ({
  translation,
  panelIndex,
  setComparedTranslationsList,
  setComparedTranslationsData,
  setCurrentOpenTranslationIndex,
  toggleOpenComparedTranslationHeader,
}) => {
  const onClickMinusButton = useCallback(
    (event) => {
      event.stopPropagation();
      setComparedTranslationsList((prevState) =>
        prevState.filter((transl, index) => index !== panelIndex)
      );
      setComparedTranslationsData((prevState) =>
        prevState.filter((transl, index) => index !== panelIndex)
      );
    },
    [panelIndex]
  );

  const onClickPlusButton = useCallback((event) => {
    event.stopPropagation();
    setComparedTranslationsList((prevState) => [...prevState, {}]);
    setCurrentOpenTranslationIndex(panelIndex + 1);
  }, []);

  return (
    <ComparedTranslationHeaderContainer
      onClick={toggleOpenComparedTranslationHeader}
    >
      <HeaderInfoContainer>
        {translation.bible_code && (
          <>
            <SelectedTranslationAbbreviation
              isActive={translation.abbreviation}
            >
              {translation.bible_code.toUpperCase()}
            </SelectedTranslationAbbreviation>

            <VerticalLine height={"15px"} />
          </>
        )}

        <SelectedTranslationText>
          {translation?.bible_name || "Выберите перевод"}
        </SelectedTranslationText>
      </HeaderInfoContainer>

      <TranslationHeaderIconsContainer>
        {panelIndex !== 0 && (
          <>
            {panelIndex > 1 && (
              <TranslationHeaderIcon onClick={onClickMinusButton}>
                <MinusTranslationIcon />
              </TranslationHeaderIcon>
            )}
            {translation?.bible_name && (
              <TranslationHeaderIcon onClick={onClickPlusButton}>
                <PlusTranslationIcon />
              </TranslationHeaderIcon>
            )}
          </>
        )}
      </TranslationHeaderIconsContainer>
    </ComparedTranslationHeaderContainer>
  );
};

export default TranslationComparePanelHeader;
