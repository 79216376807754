import React, { useCallback, useEffect, useRef, memo, useState } from "react";
import styled from "styled-components";

const VerseContainer = styled.div`
  display: flex;
  gap: 3px;
  padding: 0 12px;
  border-radius: 5px;
  margin-right: 8px;
  background: ${({ isSelected }) => (isSelected ? "#cacaca" : "none")};
  cursor: ${({ inComponent }) =>
    inComponent !== "simple_modal" ? "pointer" : "default"};

  &:hover {
    background: ${({ isSelected }) =>
      isSelected ? "#cacaca" : "rgb(223 223 223)"};
    transition: 0.2s;
  }
`;

const VerseTextContainer = styled.div`
  flex: 1;
  height: 100%;
`;

const VerseText = styled.p`
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: ${({ fontSize }) => (fontSize ? fontSize * 1.3 : "24")}px;
  color: #1e1e1e;
`;

const VerseNumber = styled.div`
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: ${({ fontSize }) => (fontSize ? fontSize * 1.3 : "24")}px;
  color: #1e1e1e;
`;

const Verse = ({
  verse,
  bibleFontSettings,
  selectedVerses,
  setSelectedVerses,
  inComponent,
}) => {
  if (!verse.verse_num) {
    return;
  }

  const verseRef = useRef(null);

  const [verseIsSelected, setVerseIsSelected] = useState(false);

  useEffect(() => {
    const includes = selectedVerses?.filter(
      (obj) => obj.verse_num === verse.verse_num
    ).length;

    if (includes) {
      setVerseIsSelected(true);
    } else setVerseIsSelected(false);
  }, [selectedVerses, verse]);

  const handleSelectVerses = useCallback(
    (event) => {
      event.stopPropagation();

      if (inComponent !== "simple_modal") {
        if (selectedVerses?.length) {
          const selectedVerseNums = selectedVerses
            .map((verse) => verse.verse_num)
            .sort((a, b) => a - b);
          const firstNum = selectedVerseNums[0];
          const lastNum = selectedVerseNums[selectedVerseNums.length - 1];

          if (!selectedVerseNums.includes(verse.verse_num)) {
            if (verse.verse_num === firstNum - 1) {
              setSelectedVerses((prevState) => [verse, ...prevState]);
            } else if (verse.verse_num === lastNum + 1) {
              setSelectedVerses((prevState) => [...prevState, verse]);
            } else {
              setSelectedVerses([verse]);
            }
          } else {
            if (verse.verse_num === firstNum || verse.verse_num === lastNum) {
              const withoutCurrentVerse = selectedVerses
                .filter((curVerse) => curVerse.verse_num !== verse.verse_num)
                .sort();
              if (withoutCurrentVerse.length) {
                setSelectedVerses(withoutCurrentVerse);
              } else {
                setSelectedVerses([]);
              }
            } else {
              setSelectedVerses([]);
            }
          }
        } else {
          setSelectedVerses([verse]);
        }
      }
    },
    [selectedVerses, verse, inComponent, setSelectedVerses]
  );

  useEffect(() => {
    if (verseIsSelected && verseRef.current) {
      verseRef.current.scrollIntoView({ block: "center" });
    }
  }, [verseRef.current]);

  return (
    <VerseContainer
      ref={verseRef}
      isSelected={verseIsSelected}
      inComponent={inComponent}
      onClick={handleSelectVerses}
    >
      <VerseNumber
        fontFamily={bibleFontSettings.fontVariant}
        fontSize={bibleFontSettings.fontSize}
      >
        {verse.verse_num}.
      </VerseNumber>
      <VerseTextContainer>
        <VerseText
          fontFamily={bibleFontSettings.fontVariant}
          fontSize={bibleFontSettings.fontSize}
        >
          {verse.verse_text}
        </VerseText>
      </VerseTextContainer>
    </VerseContainer>
  );
};

export default memo(Verse);
