import React, { memo, useCallback, useState } from "react";
import { toast, Slide } from "react-toastify";
import BiblePageCopyIcon from "../../../common/assets/icons/bible_page_icons/bible_page_copy_icon";
import { BiblePageSidebarItem } from ".";
import BibleCopyModalWindow from "./bible_copy_modal_window";
import { createPortal } from "react-dom";

const BiblePageSidebarCopy = ({
  bibleTranslations,
  selectedVerses,
  selectedSearchVerse,
  currentBibleScreen,
}) => {
  const [openCopyModal, setOpenCopyModal] = useState(false);

  const showNotification = () => {
    toast("Стих скопирован!", {
      position: "top-left",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      closeButton: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      pauseOnFocusLoss: false,
      transition: Slide,
    });
  };

  const copyToClipboard = async (text) => {
    if (!navigator.clipboard) {
      alert("Копирование не поддерживается вашим браузером.");
      return;
    }

    await navigator.clipboard.writeText(text).then(
      () => showNotification(),
      (error) => {
        console.error("Ошибка копирования: ", error);
      }
    );
  };

  const handleOpenCopyModal = useCallback(() => {
    setOpenCopyModal(true);
  }, []);

  return (
    <>
      <BiblePageSidebarItem onClick={handleOpenCopyModal}>
        <BiblePageCopyIcon />
        <p>Копировать</p>
      </BiblePageSidebarItem>

      {openCopyModal &&
        createPortal(
          <BibleCopyModalWindow
            bibleTranslations={bibleTranslations}
            currentBibleScreen={currentBibleScreen}
            selectedVerses={selectedVerses}
            selectedSearchVerse={selectedSearchVerse}
            setOpenCopyModal={setOpenCopyModal}
            copyToClipboard={copyToClipboard}
          />,
          document.body
        )}
    </>
  );
};

export default memo(BiblePageSidebarCopy);
