import React, { useState, useMemo, useEffect, useCallback } from "react";
import styled from "styled-components";
import axios from "../../axios_config";
import AuthPasswordInput from "./auth_components/auth_password_input";
import AuthTextInput from "./auth_components/auth_text_input";
import OAuthGoogleButton from "./auth_components/oauth_google_button";
import LoginButton from "./auth_components/auth_login_button";
import { BASE_API_URL } from "../../common/endpoints";
import { useUserDataContext } from "../../user_data_context";
import { useNavigate } from "react-router-dom";
import { useAuthPageContext } from "./auth_page_context";
import { Dot, emailRegex, Loader } from "./auth_page";

const AuthInputsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const AuthBottomButtonsContainer = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AuthErrorMessage = styled.div`
  position: absolute;
  top: -23px;
  left: 0;
  z-index: 5;
  width: 100%;
  text-align: center;
  color: #ef6f6f;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  user-select: none;

  @media (max-width: 440px) {
    font-size: 10px;
  }
`;

const PasswordRecoveryButton = styled.div`
  margin-top: 3px;
  display: flex;
  justify-content: end;
  user-select: none;

  p {
    width: max-content;
    font-size: 16px;
    font-weight: 400;
    line-height: 110%;
    background: linear-gradient(
      180deg,
      rgba(81, 114, 234) -18.38%,
      rgba(122, 90, 238) 108.82%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: 0.2s;
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  ${({ disabled }) =>
    disabled &&
    `
      opacity: 0.6;
      cursor: default;

         &:hover {
         box-shadow: none;
      }
  `}
`;

const PasswordRecoveryLoader = styled(Loader)`
  font-size: 16px;
  font-weight: 400;
  line-height: 110%;
  color: rgb(81, 114, 234);

  span {
    padding: 0;
    margin-top: -5px;
  }
`;

const validateForm = ({ email, password }) => {
  const errors = {};
  if (!email.trim()) errors.email = "Это поле обязательно для заполнения";
  if (!password.trim()) errors.password = "Это поле обязательно для заполнения";
  return errors;
};

const LoginPage = () => {
  const navigate = useNavigate();
  const { fetchUserData, userLanguage } = useUserDataContext();
  const {
    redirectPath,
    setCurrentAuthScreen,
    email,
    setEmail,
    setVerificationToken,
  } = useAuthPageContext();

  const [password, setPassword] = useState("");
  const [validationMessages, setValidationMessages] = useState({});
  const [authError, setAuthError] = useState("");
  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loadingPassRecovery, setLoadingPassRecovery] = useState(false);

  const userFormData = useMemo(
    () => ({
      user_email: email,
      user_password: password,
    }),
    [email, password]
  );

  useEffect(() => {
    setAuthError("");
  }, [email, password]);

  const handleInputChange = (field, value) => {
    if (field === "email") {
      setEmail(value);
    } else if (field === "password") {
      setPassword(value);
    }

    setValidationMessages((prevMessages) => ({
      ...prevMessages,
      [field]: "",
    }));
  };

  const onClickAuthButton = useCallback(
    async (event) => {
      event.stopPropagation();

      if (loadingAuth) return;

      const errors = validateForm({ email, password });
      setValidationMessages(errors);

      if (Object.keys(errors).length > 0) return;

      setLoadingAuth(true);

      try {
        const response = await axios.post(
          `${BASE_API_URL}/auth/bycredentials`,
          userFormData
        );

        const { access_token } = response.data;

        if (access_token) {
          localStorage.setItem("access_token", access_token);
          fetchUserData();
          navigate(redirectPath, { replace: true });
        }
      } catch (error) {
        const status = error.response?.status;

        if (status === 403) {
          setAuthError(
            "Неверный логин или пароль. Пожалуйста, попробуйте снова."
          );
        } else {
          setAuthError("Ошибка авторизации. Повторите попытку позже.");
        }

        console.error(
          "Unexpected error:",
          error.response?.data?.message || error.message
        );
      } finally {
        setLoadingAuth(false);
      }
      setLoadingAuth(false);
    },
    [email, password, userFormData, fetchUserData, navigate, redirectPath]
  );

  const onClickPasswordRecoveryButton = useCallback(
    async (event) => {
      event.stopPropagation();
      if (loadingPassRecovery) return;
      if (!email)
        return setAuthError(
          "Обязательно напишите почту, чтобы восстановить пароль"
        );
      if (!emailRegex.test(email))
        return setAuthError("Некорректный адрес почты");

      setLoadingPassRecovery(true);
      try {
        const response = await axios.post(
          `${BASE_API_URL}/auth/sendcode/reset`,
          {
            user_email: email,
            user_lang: userLanguage,
          }
        );

        setVerificationToken(response.data.verification_token);
        setCurrentAuthScreen("password_recovery_code");
      } catch (error) {
        if (error.response?.status === 404) {
          setAuthError("Пользователь с такой почтой не найден");
          return;
        }

        console.error(
          "Password recovery failed:",
          error.response?.data?.message || error.message
        );
        setAuthError("Ошибка восстановления пароля. Попробуйте позже");
      } finally {
        setLoadingPassRecovery(false);
      }
      setLoadingPassRecovery(false);
    },
    [email, emailRegex, setCurrentAuthScreen, userLanguage]
  );

  return (
    <div>
      <AuthInputsContainer>
        {authError && <AuthErrorMessage>{authError}</AuthErrorMessage>}

        <AuthTextInput
          placeholder={"Почта"}
          validationMessage={validationMessages.email}
          value={email}
          onChange={(e) => handleInputChange("email", e.target.value)}
        />
        <AuthPasswordInput
          placeholder={"Пароль"}
          validationMessage={validationMessages.password}
          value={password}
          leftHandMessage={true}
          onChange={(e) => handleInputChange("password", e.target.value)}
        />
      </AuthInputsContainer>

      <PasswordRecoveryButton
        disabled={loadingPassRecovery}
        onClick={onClickPasswordRecoveryButton}
      >
        {!loadingPassRecovery ? (
          <p>Забыли пароль?</p>
        ) : (
          <PasswordRecoveryLoader>
            Отправка кода на почту
            <Dot>.</Dot>
            <Dot>.</Dot>
            <Dot>.</Dot>
          </PasswordRecoveryLoader>
        )}
      </PasswordRecoveryButton>

      <AuthBottomButtonsContainer>
        <LoginButton disabled={loadingAuth} onClick={onClickAuthButton}>
          {!loadingAuth ? (
            "Войти"
          ) : (
            <Loader>
              Вход
              <Dot>.</Dot>
              <Dot>.</Dot>
              <Dot>.</Dot>
            </Loader>
          )}
        </LoginButton>
        <OAuthGoogleButton
          buttonText={"Войти с Google"}
          redirectPath={redirectPath}
        />
      </AuthBottomButtonsContainer>
    </div>
  );
};

export default LoginPage;
