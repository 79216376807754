import React, { useCallback } from "react";
import FramedBibleVerse from "../../../../common/components/framed_bible_verse";

const SearchVerse = ({
  verse,
  bibleSearchWord,
  selectedSearchVerse,
  currentTranslation,
  setBibleChapterModalData,
  setSelectedSearchVerse,
}) => {
  const handleSelectVerses = useCallback((verse) => {
    setSelectedSearchVerse((prevState) =>
      prevState && prevState._id === verse._id ? null : verse
    );
  }, []);

  const onBibleSearchAddress = useCallback(
    (verse) => {
      setBibleChapterModalData({
        bibleId: verse.bible_code,
        bookId: verse.book_code,
        chapterId: verse.chapter_num,
        selectedVerses: verse.verse_num,
        searchWord: bibleSearchWord,
      });
    },
    [bibleSearchWord]
  );

  return (
    <div>
      <FramedBibleVerse
        background={"#EAEAEA"}
        bibleSearchWord={bibleSearchWord}
        inComponent={"bible_search"}
        verse={verse}
        isSelected={selectedSearchVerse?._id === verse?._id}
        currentTranslation={currentTranslation}
        onBibleSearchAddress={onBibleSearchAddress}
        onBibleSearchVerse={handleSelectVerses}
      />
    </div>
  );
};

export default SearchVerse;
