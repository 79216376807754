import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import BookNavigation from "./book_navigation";
import SimpleListLoader from "./simple_list_loader";
import { BIBLE_BOOK_NAMES_DATA } from "../../../../../../pages/bible/bible_page_data";
import { BASE_API_URL } from "../../../../../endpoints";
import VerticalLine from "../../../../../assets/icons/vertical_line";

const BibleModalBooksNavWrapper = styled.div`
  overflow: hidden;
  background: #fff;
  border-radius: 15px;
`;

const BibleModalBooksNavContainer = styled.div`
  padding: 7px 10px 8px 12px;
  overflow-y: auto;
  max-height: calc(100% - 30px);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 13px;
`;

const BiblePartsButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 30px;
  width: 100%;
  ${({ activeScreenDesktop }) =>
    activeScreenDesktop !== "bible_modal_search" &&
    "border-bottom: 1px solid #cacaca;"}
`;

const BiblePartButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  color: ${({ isSelected }) => (isSelected ? "#1E1E1E" : "#828282")};
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  user-select: none;
  cursor: pointer;
`;

const BibleModalBooksNav = ({
  currentTranslation,
  currentBibleParams,
  activeScreenDesktop,
  setCurrentTranslationParams,
  setSelectedVerses,
}) => {
  const scrollContainerRef = useRef(null);
  const [expandedBookID, setExpandedBookID] = useState("");
  const [currentTranslationData, setCurrentTranslationData] = useState([]);
  const [selectedTestament, setSelectedTestament] = useState("ot"); // ot, nt
  const [sortedBooksByTestaments, setSortedBooksByTestaments] = useState([]);
  const [isTranslationLoading, setTranslationLoading] = useState(false);

  const activeBookId = currentBibleParams.bookId;

  useEffect(() => {
    if (activeBookId) {
      setExpandedBookID(activeBookId);
    }
  }, [activeBookId]);

  useEffect(() => {
    return () => setSelectedTestament("ot");
  }, []);

  const handleTestaments = useCallback((part) => {
    setSelectedTestament(part);
  }, []);

  useEffect(() => {
    const currentTestament = currentTranslationData?.find(
      (book) => book.book_code === activeBookId
    );

    if (currentTestament) {
      setSelectedTestament(currentTestament.book_place);
    }
  }, [currentTranslationData, activeBookId]);

  useEffect(() => {
    if (currentTranslationData) {
      const sortByTestaments = currentTranslationData.filter(
        (book) => book.book_place === selectedTestament
      );

      setSortedBooksByTestaments(sortByTestaments);
    }
  }, [currentTranslationData, selectedTestament]);

  useEffect(() => {
    if (currentTranslation) {
      setTranslationLoading(true);
      fetch(
        `${BASE_API_URL}/bible/books?bible=${currentTranslation.bible_code}`
      )
        .then((response) => response.json())
        .then((bibleData) => {
          const updateBooksNames = bibleData.books.map((book) => {
            if (BIBLE_BOOK_NAMES_DATA[currentTranslation.bible_locale]) {
              return {
                ...book,
                book_name:
                  BIBLE_BOOK_NAMES_DATA[currentTranslation.bible_locale][
                    book.book_code
                  ].name,
                number:
                  BIBLE_BOOK_NAMES_DATA[currentTranslation.bible_locale][
                    book.book_code
                  ].number,
              };
            } else {
              return book;
            }
          });

          const sortedBooks = updateBooksNames.sort(
            (a, b) => a.number - b.number
          );

          setCurrentTranslationData(sortedBooks);

          if (activeBookId) {
            setExpandedBookID(activeBookId);
          }
        })
        .finally(() => {
          setTranslationLoading(false);
        });
    }
  }, [currentTranslation]);

  return (
    <BibleModalBooksNavWrapper>
      <BiblePartsButtonsContainer activeScreenDesktop={activeScreenDesktop}>
        <BiblePartButton
          isSelected={selectedTestament === "ot"}
          onClick={() => handleTestaments("ot")}
        >
          Ветхий Завет
        </BiblePartButton>
        <VerticalLineStyled />
        <BiblePartButton
          isSelected={selectedTestament === "nt"}
          onClick={() => handleTestaments("nt")}
        >
          Новый Завет
        </BiblePartButton>
      </BiblePartsButtonsContainer>

      <>
        {!isTranslationLoading ? (
          <BibleModalBooksNavContainer ref={scrollContainerRef}>
            {currentTranslationData ? (
              sortedBooksByTestaments.map((book) => (
                <BookNavigation
                  key={book.book_code}
                  scrollContainerRef={scrollContainerRef}
                  setIsActive={setExpandedBookID}
                  isExpanded={expandedBookID === book.book_code}
                  book={book}
                  setSelectedVerses={setSelectedVerses}
                  currentBibleParams={currentBibleParams}
                  setCurrentTranslationParams={setCurrentTranslationParams}
                />
              ))
            ) : (
              <div>Пожалуйста, выберите перевод Библии в секции переводов.</div>
            )}
          </BibleModalBooksNavContainer>
        ) : (
          <SimpleListLoader />
        )}
      </>
    </BibleModalBooksNavWrapper>
  );
};

export default memo(BibleModalBooksNav);
