import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { createPortal } from "react-dom";
import styled from "styled-components";
import merge from "lodash/merge";
import { debounce } from "lodash";
import axios from "../../axios_config";
import { LESSON_COLORS_THEME } from "../../common/utils/enums";
import { useAppContext } from "../../app_context";
import { useUserDataContext } from "../../user_data_context";
import { BASE_API_URL } from "../../common/endpoints";
import BibleChapterModalWindow from "../../common/components/modal_windows/bible_chapter_modal_window";
import LessonPageHeader from "./user_lesson_page_header";
import FinishLessonButton from "./finish_lesson_button";
import UserLessonComponentsList from "./user_lesson_components_list";
import AfterFinishLessonModal from "./user_lesson_modal_windows/after_finish_lesson_modal";
import AfterFinishCourseModal from "./user_lesson_modal_windows/after_finish_course_modal";
import CertificateProgressBarModal from "./user_lesson_modal_windows/certificate_progress_bar_modal";
import CourseCertificateModal from "./user_lesson_modal_windows/course_certificate_modal";
import CourseFeedbackModal from "./user_lesson_modal_windows/course_feedback_modal";
import BibleModalWindow from "../../common/components/modal_windows/bible_modal_window";
import useIsMobile from "../../common/hooks/use_is_mobile";

const UserLessonPageContainer = styled.div`
  position: relative;
  max-width: 100%;
  height: calc(100vh - 80px);
  overflow: auto;
  ${({ darkTheme }) => darkTheme && "background: #232323;"}

  @media (max-width: 1024px) {
    height: calc(100vh - 96px);
  }

  @media (max-width: 744px) {
    height: calc(100vh);
  }

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const UserLessonPage = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const lessonNumber = searchParams.get("n");
  const { appColorTheme, setShowAppHeader } = useAppContext();
  const { userData, fetchUserData } = useUserDataContext();
  const userLessonPageRef = useRef(null);

  const [lessonData, setLessonData] = useState({});
  const [courseData, setCourseData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [textBibleModalData, setTextBibleModalData] = useState({});
  const [shouldShowFinishButton, setShouldShowFinishButton] = useState(false);
  const [stopDialogueIndex, setStopDialogueIndex] = useState(null);
  const [filteredComponents, setFilteredComponents] = useState([]);
  const [userLessonInfo, setUserLessonInfo] = useState({});
  const [lessonIsFinished, setLessonIsFinished] = useState(null);
  const [isLastLesson, setIsLastLesson] = useState(false);
  const [userAnswersData, setUserAnswersData] = useState([]);
  const [afterFinishLessonModal, setAfterFinishLessonModal] = useState(false);
  const [courseFinishModalWindow, setCourseFinishModalWindow] = useState(false);
  const [certificateProgressBarModal, setCertificateProgressBarModal] =
    useState(false);
  const [courseCertificateModal, setCourseCertificateModal] = useState(false);
  const [courseFeedbackModal, setCourseFeedbackModal] = useState(false);
  const [openBibleModalWindow, setOpenBibleModalWindow] = useState(false);
  const [bibleVersesData, setBibleVersesData] = useState({});

  const isMobile = useIsMobile(744);
  const lessonId = id;
  const userId = userData._id;
  const savedScrollPosition = +localStorage.getItem(`pos-${lessonId}`);

  useEffect(() => {
    if (isMobile) {
      setShowAppHeader(false);
    }
    return () => setShowAppHeader(true);
  }, [isMobile]);

  useEffect(() => {
    if (lessonIsFinished) {
      localStorage.removeItem(`pos-${lessonId}`);
    }
  }, [lessonIsFinished]);

  useEffect(() => {
    if (
      savedScrollPosition !== null &&
      userLessonPageRef.current &&
      courseData.course_lessons?.length > 0
    ) {
      userLessonPageRef.current.scrollTo({
        top: savedScrollPosition,
      });
    }
  }, [
    userLessonPageRef.current,
    savedScrollPosition,
    courseData.course_lessons?.length,
  ]);

  useEffect(() => {
    const container = userLessonPageRef.current;

    const handleScroll = debounce(() => {
      localStorage.setItem(`pos-${lessonId}`, container.scrollTop);
    }, 400);

    if (
      lessonIsFinished !== null &&
      !lessonIsFinished &&
      lessonId &&
      container
    ) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [userLessonPageRef, lessonIsFinished, lessonId]);

  useEffect(() => {
    if (certificateProgressBarModal) {
      const timer = setTimeout(() => {
        setCourseCertificateModal(true);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [certificateProgressBarModal]);

  useEffect(() => {
    if (!courseData._id || !userData._id) return;

    const courseIsFinished =
      userData.user_activity?.find(
        (item) => item.type === "course" && item.course_id === courseData._id
      )?.course_status === "finished";

    if (isLastLesson && lessonIsFinished && !courseIsFinished) {
      setAfterFinishLessonModal(true);
    }
  }, [isLastLesson, lessonIsFinished, courseData._id, userData]);

  useEffect(() => {
    if (courseData._id && lessonData._id) {
      const lessonIsLast =
        courseData.course_lessons?.length > 0 &&
        courseData.course_lessons[courseData.course_lessons.length - 1]._id ===
          lessonData._id;

      setIsLastLesson(lessonIsLast);
    }
  }, [courseData, lessonData]);

  useEffect(() => {
    fetchUserData();
  }, [lessonIsFinished]);

  const courseMainGradient =
    LESSON_COLORS_THEME[appColorTheme]?.GRADIENTS?.[
      courseData.course_main_color
    ];
  const courseMainColor =
    LESSON_COLORS_THEME[appColorTheme]?.COLORS?.[courseData.course_main_color];

  const fetchCourse = useCallback(async (courseId) => {
    try {
      const response = await axios.get(`${BASE_API_URL}/courses/${courseId}`);
      setCourseData(response.data);
    } catch (error) {
      console.error("Ошибка при запросе:", error);
    }
  }, []);

  useEffect(() => {
    if (lessonData.lesson_course?._id) {
      const courseId = lessonData.lesson_course._id;
      fetchCourse(courseId);
    }
  }, [lessonData.lesson_course?._id]);

  useEffect(() => {
    if (userData._id && courseData._id && lessonData._id) {
      const currentCourseAtUser = userData.user_activity.find(
        (item) => item.type === "course" && item.course_id === courseData._id
      );

      if (currentCourseAtUser?.course_id) {
        const lessonStatusAtUser = currentCourseAtUser?.course_lessons?.find(
          (lesson) => lesson._id === lessonData._id
        )?.lesson_status;

        const lessonIsFinished =
          lessonStatusAtUser === "finished" ? true : false;

        setLessonIsFinished(lessonIsFinished);
      }
    }
  }, [userData, courseData, lessonData._id]);

  useEffect(() => {
    setShouldShowFinishButton(
      lessonData.lesson_data?.length === filteredComponents?.length &&
        !isLoading
    );
  }, [lessonData.lesson_data?.length, filteredComponents?.length, isLoading]);

  useEffect(() => {
    if (stopDialogueIndex === null) {
      setFilteredComponents(lessonData.lesson_data);
    } else {
      const index = stopDialogueIndex;
      if (index >= 0 && index < lessonData.lesson_data.length) {
        setFilteredComponents(lessonData.lesson_data.slice(0, index + 1));
      }
    }
  }, [stopDialogueIndex, lessonData.lesson_data]);

  useEffect(() => {
    if (lessonData.lesson_data?.length) {
      const index = lessonData.lesson_data.findIndex((component) => {
        if (component?.type === "DIALOGUE") {
          const lastSelectedBranch =
            component.selectedBranches?.[component.selectedBranches.length - 1];

          if (lastSelectedBranch && component.data[lastSelectedBranch]) {
            const hasOtherBranches =
              component.data[lastSelectedBranch].answers?.answers_data?.length >
              0;
            return hasOtherBranches;
          }
        }
        return false;
      });

      if (index !== -1) {
        setStopDialogueIndex(index);
      } else {
        setStopDialogueIndex(null);
      }
    }
  }, [lessonData.lesson_data]);

  const fetchUserLessonData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios(
        BASE_API_URL +
          `/userstudy/find?studyLessonId=${lessonId}&studyUserId=${userId}`
      );

      const { lesson, ...userLessonInfo } = response.data;

      setUserLessonInfo(userLessonInfo);
      setLessonData(lesson);
      setIsLoading(false);
    } catch (error) {
      console.error("Ошибка загрузки данных урока:", error);
    }
  }, [lessonId, userId]);

  useEffect(() => {
    if (lessonData._id !== lessonId && lessonId && userId) {
      fetchUserLessonData();
    }
  }, [lessonId, userId, lessonData._id]);

  useEffect(() => {
    if (userLessonInfo._id && userAnswersData?.length) {
      localStorage.setItem(userLessonInfo._id, JSON.stringify(userAnswersData));
    }
  }, [userAnswersData, userLessonInfo._id]);

  useEffect(() => {
    if (userLessonInfo._id && !lessonIsFinished) {
      const storageData = JSON.parse(localStorage.getItem(userLessonInfo._id));

      if (storageData?.length) {
        setUserAnswersData(storageData);
      } else {
        localStorage.setItem(userLessonInfo._id, JSON.stringify([]));
      }
    }
  }, [userLessonInfo._id, lessonIsFinished]);

  useEffect(() => {
    if (
      userLessonInfo._id &&
      lessonData._id &&
      userAnswersData?.length &&
      !lessonIsFinished
    ) {
      const updatedLessonData = lessonData.lesson_data.map(
        (lessonComponent) => {
          const matchingAnswer = userAnswersData.find(
            (answer) => answer.id === lessonComponent.id
          );

          return matchingAnswer
            ? merge({}, lessonComponent, matchingAnswer)
            : lessonComponent;
        }
      );

      setLessonData((prevState) => ({
        ...prevState,
        lesson_data: updatedLessonData,
      }));
    }
  }, [userLessonInfo._id, lessonIsFinished, userAnswersData, lessonData._id]);

  return (
    <UserLessonPageContainer
      darkTheme={appColorTheme === "DARK"}
      ref={userLessonPageRef}
    >
      <LessonPageHeader
        appColorTheme={appColorTheme}
        lessonData={lessonData}
        lessonNumber={lessonNumber}
      />

      <UserLessonComponentsList
        userLessonPageRef={userLessonPageRef}
        isLoading={isLoading}
        filteredComponents={filteredComponents}
        appColorTheme={appColorTheme}
        courseMainColor={courseMainColor}
        courseMainGradient={courseMainGradient}
        lessonIsFinished={lessonIsFinished}
        userAnswersData={userAnswersData}
        setLessonData={setLessonData}
        setUserAnswersData={setUserAnswersData}
        setTextBibleModalData={setTextBibleModalData}
      />

      {shouldShowFinishButton && !!courseMainGradient && (
        <FinishLessonButton
          userLessonInfo={userLessonInfo}
          userAnswersData={userAnswersData}
          courseMainGradient={courseMainGradient}
          courseData={courseData}
          lessonIsFinished={lessonIsFinished}
          filteredComponents={filteredComponents}
          setLessonIsFinished={setLessonIsFinished}
          setAfterFinishLessonModal={setAfterFinishLessonModal}
          fetchUserLessonData={fetchUserLessonData}
        />
      )}

      {textBibleModalData.bibleId && (
        <BibleChapterModalWindow
          bibleModalData={textBibleModalData}
          setOpen={setTextBibleModalData}
          setBibleVersesData={setBibleVersesData}
          setOpenBibleModalWindow={setOpenBibleModalWindow}
        />
      )}

      {openBibleModalWindow && (
        <BibleModalWindow
          setOpenBibleModalWindow={setOpenBibleModalWindow}
          editedVersesData={bibleVersesData}
          setEditedVersesData={setBibleVersesData}
          inComponent={"simple_modal"}
        />
      )}

      {afterFinishLessonModal &&
        createPortal(
          <AfterFinishLessonModal
            userData={userData}
            lessonData={lessonData}
            courseData={courseData}
            lessonNumber={lessonNumber}
            isLastLesson={isLastLesson}
            setAfterFinishLessonModal={setAfterFinishLessonModal}
            setCourseFinishModalWindow={setCourseFinishModalWindow}
            setCourseFeedbackModal={setCourseFeedbackModal}
            fetchUserLessonData={fetchUserLessonData}
          />,
          document.body
        )}

      {courseFinishModalWindow &&
        createPortal(
          <AfterFinishCourseModal
            courseData={courseData}
            setCourseFinishModalWindow={setCourseFinishModalWindow}
            setCertificateProgressBarModal={setCertificateProgressBarModal}
          />,
          document.body
        )}

      {certificateProgressBarModal &&
        createPortal(
          <CertificateProgressBarModal
            setCertificateProgressBarModal={setCertificateProgressBarModal}
          />,
          document.body
        )}

      {courseCertificateModal &&
        createPortal(
          <CourseCertificateModal
            courseData={courseData}
            userData={userData}
            setCourseCertificateModal={setCourseCertificateModal}
            setCourseFeedbackModal={setCourseFeedbackModal}
          />,
          document.body
        )}

      {courseFeedbackModal &&
        createPortal(
          <CourseFeedbackModal
            courseData={courseData}
            userData={userData}
            setCourseFeedbackModal={setCourseFeedbackModal}
          />,
          document.body
        )}
    </UserLessonPageContainer>
  );
};

export default memo(UserLessonPage);
