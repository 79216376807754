import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import TranslationsList from "../../../../../../pages/bible/bible_page_desktop/bible_navigation_panel_desktop/bible_translations_panel/translations_list";
import TranslationPanelInput from "../../../../../../pages/bible/bible_page_desktop/bible_navigation_panel_desktop/bible_translations_panel/translation_panel_input";

const LanguagesButton = styled.div`
  color: #f8d254;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.4px;
`;

const ModalWindowTranslations = ({
  bibleTranslations,
  currentBibleLanguage,
  translation,
  setCurrentScreen,
  setBibleTranslations,
  setCurrentTranslationParams,
  setSelectedVerses,
}) => {
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [selectedTranslations, setSelectedTranslations] = useState([]);

  const getFilteredTranslation = useCallback(
    (bibleTranslations, inputSearchValue, currentBibleLanguage) => {
      const sortedTranslationByFavorite = bibleTranslations?.sort(
        (tr1, tr2) => tr2.isFavorite - tr1.isFavorite
      );
      const filteredByLanguage = sortedTranslationByFavorite?.filter(
        (translation) =>
          translation.bible_locale === currentBibleLanguage.code ||
          translation.isFavorite
      );

      const filteredBySearch = filteredByLanguage?.filter(
        (translation) =>
          translation.bible_code
            .toLowerCase()
            .includes(inputSearchValue.toLowerCase()) ||
          translation.bible_name
            .toLowerCase()
            .includes(inputSearchValue.toLowerCase()) ||
          translation.isFavorite
      );

      return filteredBySearch;
    },
    []
  );

  useEffect(() => {
    if (bibleTranslations) {
      setSelectedTranslations(
        getFilteredTranslation(
          bibleTranslations,
          inputSearchValue,
          currentBibleLanguage
        )
      );
    }
  }, [bibleTranslations, inputSearchValue, currentBibleLanguage]);

  const onTranslationClick = useCallback((translation) => {
    setCurrentTranslationParams((prevState) => ({
      ...prevState,
      bibleId: translation.bible_code,
    }));
    setSelectedVerses([]);
  }, []);

  const stopPropagationOnClick = (e) => e.stopPropagation();

  return (
    <div onClick={stopPropagationOnClick}>
      <TranslationPanelInput
        placeholder={"Поиск перевода..."}
        button={
          <LanguagesButton>
            {currentBibleLanguage?.abbreviation?.toUpperCase()}
          </LanguagesButton>
        }
        onClickButton={() => setCurrentScreen("languages")}
        inputSearchValue={inputSearchValue}
        setInputSearchValue={setInputSearchValue}
      />
      <TranslationsList
        selectedTranslations={selectedTranslations}
        currentTranslation={translation}
        setBibleTranslations={setBibleTranslations}
        onTranslationClick={onTranslationClick}
      />
    </div>
  );
};

export default memo(ModalWindowTranslations);
