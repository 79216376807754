import React, { memo, useCallback, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import imageCompression from "browser-image-compression";
import axios from "../../axios_config";
import RemoveIcon from "../assets/icons/remove_icon";
import { BASE_API_URL } from "../endpoints";

const ImageDropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DropzoneContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 70px;
  padding: 0 10px;
  border-radius: 8px;
  border: 1px dashed #5172ea;
  cursor: pointer;
  transition: 0.3s;
  background: ${({ dragging }) =>
    dragging ? "rgb(181 175 228)" : "rgb(203 199 234)"};

  &:hover {
    background: rgb(181 175 228);
    transition: 0.2s;
  }
`;

const DropzoneText = styled.div`
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 130%;
`;

const ImagePreviewContainer = styled.div`
  position: relative;
  width: max-content;
  height: 46px;
  border-radius: 8px;
  border: 1px solid #5172ea;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
  user-select: none;
`;

const VideoPreview = styled.video`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
  user-select: none;

  &::-webkit-media-controls {
    display: none !important;
  }

  &::-webkit-media-controls-overlay-play-button {
    display: none !important;
  }

  &::-webkit-media-controls-start-playback-button {
    display: none !important;
  }
`;

const RemoveIconContainer = styled.div`
  position: absolute;
  right: -5px;
  top: -6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 14px;
  background: #fff;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    transition: all 0.2s;
  }
`;

const RemoveIconStyled = styled(RemoveIcon)`
  width: 9px;
  height: 9px;
`;

const ErrorMessage = styled.div`
  position: absolute;
  top: -17px;
  left: 0;
  color: red;
  font-size: 13px;
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
`;

const pulseAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
`;

const Dot = styled.span`
  margin: 0 2px;
  padding-bottom: 4px;
  animation: ${pulseAnimation} 1s infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const ImageDropzone = ({
  imageUrl,
  handleImageSettings,
  isCurrentImage,
  weightLimit = 3,
}) => {
  const [dragging, setDragging] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const isVideo = imageUrl?.endsWith(".mp4");

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  const fetchImage = async (file) => {
    const allowedFormats = [
      "image/jpeg",
      "image/png",
      "image/gif",
      "video/mp4",
    ];

    if (!allowedFormats.includes(file.type)) {
      setError("Необходимый формат файла: jpg, png, gif, mp4");
      setTimeout(clearError, 3000);
      return;
    }

    let compressedFile = file;

    if (file.type.startsWith("image/")) {
      const weightLimitInBytes = weightLimit ? weightLimit * 1024 * 1024 : null;

      const options = {
        maxSizeMB: weightLimit,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      setLoading(true);

      try {
        compressedFile = await imageCompression(file, options);

        if (weightLimitInBytes && compressedFile.size > weightLimitInBytes) {
          setError(
            `Файл слишком большой после сжатия. Максимальный размер: ${weightLimit} MB`
          );
          setTimeout(clearError, 3000);
          return;
        }
      } catch (error) {
        setError("Ошибка при сжатии файла");
        setTimeout(clearError, 3000);
        console.error(error);
        return;
      }
    }

    const formData = new FormData();
    formData.append("file", compressedFile);
    formData.append("file_name", "");
    formData.append("file_prefix", "");

    setLoading(true);

    try {
      const response = await axios.post(
        BASE_API_URL + "/uploads/uploadfile",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      handleImageSettings(response.data.file_absolute_url);
      clearError();
    } catch (error) {
      setError("Ошибка при загрузке файла");
      setTimeout(clearError, 3000);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleFileInputChange = useCallback(
    (event) => {
      event.preventDefault();
      if (!imageUrl) {
        const file = event.target.files[0];
        fetchImage(file);
      }
    },
    [handleImageSettings, imageUrl]
  );

  const handleClick = useCallback(() => {
    if (fileInputRef.current && !imageUrl) {
      fileInputRef.current.click();
    }
  }, [imageUrl, fileInputRef.current]);

  const handleDrop = useCallback(
    (event) => {
      event.preventDefault();
      if (!imageUrl) {
        const file = event.dataTransfer.files[0];
        fetchImage(file);
        setDragging(false);
      }
    },
    [handleImageSettings, imageUrl]
  );

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    setDragging(true);
  }, []);

  const onRemoveImage = useCallback(() => {
    if (isCurrentImage) {
      handleImageSettings("");
    } else {
      const imageName = new URL(imageUrl).pathname.split("/").pop();
      axios
        .delete(`${BASE_API_URL}/uploads/deletefile/${imageName}`)
        .then((response) => {
          handleImageSettings("");
          clearError();
        })
        .catch((error) => {
          console.error("Ошибка при удалении файла:", error);
          setError("Ошибка при удалении файла");
        });
    }
  }, [imageUrl, isCurrentImage]);

  return (
    <ImageDropzoneContainer onClick={(event) => event.stopPropagation()}>
      {!imageUrl && (
        <DropzoneContainer
          dragging={dragging}
          onClick={handleClick}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          enabled={!imageUrl}
        >
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept={"image/jpeg, image/png, image/gif, video/mp4"}
            multiple={false}
            onChange={handleFileInputChange}
          />

          {loading ? (
            <Loader>
              Загрузка
              <Dot>.</Dot>
              <Dot>.</Dot>
              <Dot>.</Dot>
            </Loader>
          ) : (
            <DropzoneText>
              {dragging ? (
                "Отпустите файл тут"
              ) : (
                <>
                  Кликните, чтобы загрузить файл <br /> или перетащите его
                </>
              )}
            </DropzoneText>
          )}

          {error && <ErrorMessage>{error}</ErrorMessage>}
        </DropzoneContainer>
      )}

      {imageUrl && (
        <ImagePreviewContainer>
          {isVideo ? (
            <VideoPreview
              src={imageUrl}
              autoPlay
              loop
              muted
              playsInline
              controls={false}
              disablePictureInPicture
              alt="Uploaded video"
            />
          ) : (
            <ImagePreview src={imageUrl} alt="Uploaded image" />
          )}

          <RemoveIconContainer onClick={onRemoveImage}>
            <RemoveIconStyled color={"#383838"} />
          </RemoveIconContainer>
        </ImagePreviewContainer>
      )}
    </ImageDropzoneContainer>
  );
};

export default memo(ImageDropzone);
