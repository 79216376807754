import React, { memo, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import BibleSearchHeaderMobile from "./bible_search_header_mobile";
import BibleSearchResultsMobile from "./bible_search_results_mobile";
import BibleSearchSettingsMobile from "./bible_search_settings_mobile";
import { useBiblePageContext } from "../../../bible_page_context";

const BibleSearchMobileContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eaeaea;
`;

const BibleSearchMobile = ({
  bibleSearchInput,
  bibleSearchPlace,
  setBibleSearchResults,
  setBibleSearchInput,
  setSelectedSearchVerse,
  bibleSearchResults,
  currentTranslation,
  selectedSearchVerse,
  setBibleSearchPlace,
  isLoading,
  setIsLoading,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { activeScreenMobile, setActiveScreenMobile } = useBiblePageContext();

  const [inputIsEmpty, setInputIsEmpty] = useState(false);
  const [showedInputIcon, setShowedInputIcon] = useState("search_icon"); //search_icon, remove_icon
  const [searchWholeWord, setWholeWordCheckbox] = useState(false);
  const [searchWithRegister, setWithRegisterCheckbox] = useState(false);
  const [showBibleSearchResults, setShowBibleSearchResults] = useState(false);
  const [showBibleSearchSettings, setShowBibleSearchSettings] = useState(false);

  const bibleSearchTranslate = currentTranslation.bible_code;

  useEffect(() => {
    if (selectedSearchVerse?._id) {
      setShowBibleSearchSettings(false);
    }
  }, [selectedSearchVerse?._id]);

  const onSearchSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      if (bibleSearchInput.trim().length > 1) {
        setIsLoading(true);
        try {
          const response = await axios.post(
            "https://api.on-bible.com/bible/verses/lookup",
            {
              bible_code: bibleSearchTranslate,
              search_value: bibleSearchInput,
              search_option_place: bibleSearchPlace,
              search_option_whole_word: searchWholeWord,
              search_option_with_register: searchWithRegister,
            }
          );
          setBibleSearchResults({
            searchBible: bibleSearchTranslate,
            searchTestament: bibleSearchPlace,
            searchWord: bibleSearchInput,
            searchResults: response.data.results,
          });
        } catch (error) {
          console.error("Ошибка при отправке запроса", error);
        } finally {
          setShowedInputIcon("remove_icon");

          setSearchParams((prev) => {
            const newParams = new URLSearchParams(prev);
            newParams.delete("2bibleId");
            newParams.delete("selectVerses");
            newParams.delete("selectBible");
            return newParams;
          });

          setIsLoading(false);
          setShowBibleSearchResults(true);
        }
      } else {
        setInputIsEmpty(true);

        setTimeout(() => {
          setInputIsEmpty(false);
        }, 2000);
      }
    },
    [
      bibleSearchTranslate,
      bibleSearchInput,
      bibleSearchPlace,
      searchWholeWord,
      searchWithRegister,
      setSearchParams,
      setBibleSearchResults,
    ]
  );

  const onClearSearchSettings = useCallback(() => {
    setBibleSearchResults({});
    setBibleSearchInput("");
    setSelectedSearchVerse(null);
    setBibleSearchInput("");
    setShowBibleSearchResults(false);
  }, []);

  useEffect(() => {
    if (bibleSearchInput.length && activeScreenMobile === "bible_search") {
      const performSearch = async () => {
        const fakeEvent = { preventDefault: () => {} };
        await onSearchSubmit(fakeEvent);
      };
      performSearch();
    }
  }, [bibleSearchPlace, searchWholeWord, searchWithRegister]);

  useEffect(() => {
    return () => {
      setWholeWordCheckbox(false);
      setWithRegisterCheckbox(false);
      setShowedInputIcon("search_icon");
      setBibleSearchPlace("");
    };
  }, [activeScreenMobile]);

  useEffect(() => {
    setShowedInputIcon("search_icon");
  }, [bibleSearchInput]);

  return (
    <BibleSearchMobileContainer>
      <BibleSearchHeaderMobile
        bibleSearchInput={bibleSearchInput}
        inputIsEmpty={inputIsEmpty}
        showedInputIcon={showedInputIcon}
        setBibleSearchInput={setBibleSearchInput}
        setShowedInputIcon={setShowedInputIcon}
        setActiveScreenMobile={setActiveScreenMobile}
        setShowBibleSearchSettings={setShowBibleSearchSettings}
        setBibleSearchPlace={setBibleSearchPlace}
        onSearchSubmit={onSearchSubmit}
        onClearSearchSettings={onClearSearchSettings}
      />

      {showBibleSearchResults && (
        <BibleSearchResultsMobile
          isLoading={isLoading}
          bibleSearchResults={bibleSearchResults}
          currentTranslation={currentTranslation}
          bibleSearchInput={bibleSearchInput}
          bibleSearchTranslate={bibleSearchTranslate}
          selectedSearchVerse={selectedSearchVerse}
          setSelectedSearchVerse={setSelectedSearchVerse}
        />
      )}

      {showBibleSearchSettings &&
        createPortal(
          <BibleSearchSettingsMobile
            bibleSearchPlace={bibleSearchPlace}
            searchWholeWord={searchWholeWord}
            searchWithRegister={searchWithRegister}
            setWholeWordCheckbox={setWholeWordCheckbox}
            setWithRegisterCheckbox={setWithRegisterCheckbox}
            setBibleSearchPlace={setBibleSearchPlace}
            setShowBibleSearchSettings={setShowBibleSearchSettings}
          />,
          document.body
        )}
    </BibleSearchMobileContainer>
  );
};

export default memo(BibleSearchMobile);
