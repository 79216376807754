import React from "react";

const SearchSettingsLineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="4"
      viewBox="0 0 54 4"
      fill="none"
    >
      <path
        d="M2 2H52"
        stroke="url(#paint0_linear_7941_18726)"
        strokeOpacity="0.5"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_7941_18726"
          x1="25.7915"
          y1="1.81618"
          x2="25.7915"
          y2="3.08824"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SearchSettingsLineIcon;
