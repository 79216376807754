import React, { memo, useCallback, useEffect } from "react";
import styled from "styled-components";
import LeftArrowIcon from "../../../../../common/assets/icons/left_arrow_icon";
import RemoveIcon from "../../../../../common/assets/icons/remove_icon";
import { SearchIcon } from "../../../../../common/assets/icons";
import BibleSearchSettingsIcon from "../../../../../common/assets/icons/bible_page_icons/bible_search_settings_icon";

const BibleSearchHeaderContainer = styled.div`
  position: relative;
  display: flex;
  gap: 4px;
  padding: 14px 14px 0;
  max-width: 100%;
`;

const BackToChapterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 4px;
  height: 38px;
  width: 42px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
`;

const LeftArrowIconStyled = styled(LeftArrowIcon)`
  height: 16px;
  width: 16px;
`;

const BibleSearchInputContainer = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  flex: 1;
  min-height: 38px;
  max-height: 38px;
  padding-left: 14px;
  border-radius: 15px;
  background: #fff;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
  ${({ inputIsEmpty }) => inputIsEmpty && "border: 1px solid red;"}
`;

const BibleSearchInput = styled.input`
  flex: 1;
  color: #1e1e1e;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.14px;
  height: 100%;
`;

const SearchIconContainer = styled.button`
  display: flex;
  align-items: center;
  width: max-content;
  height: 100%;
  padding: 0 13px 0 8px;
  transition: 0.2s;
`;

const SearchIconStyled = styled(SearchIcon)`
  width: 16px;
  height: 16px;
`;

const RemoveIconStyled = styled(RemoveIcon)`
  width: 14px;
  height: 14px;
`;

const SearchSettingsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 36px;
  background: #fff;
  border-radius: 16px;
`;

const BibleSearchHeaderMobile = ({
  bibleSearchInput,
  inputIsEmpty,
  showedInputIcon,
  setBibleSearchInput,
  setShowedInputIcon,
  setActiveScreenMobile,
  setShowBibleSearchSettings,
  setBibleSearchPlace,
  onClearSearchSettings,
  onSearchSubmit,
}) => {
  const onClickBackButton = useCallback(
    (event) => {
      event.stopPropagation();
      setActiveScreenMobile("bible_chapter");
      onClearSearchSettings();
      setBibleSearchPlace("");
    },
    [onClearSearchSettings]
  );

  const onRemoveIconClick = useCallback(
    (event) => {
      event.stopPropagation();
      setShowedInputIcon("search_icon");
      onClearSearchSettings();
    },
    [onClearSearchSettings]
  );

  const handleSearchInput = useCallback((event) => {
    setBibleSearchInput(event.target.value);
  }, []);

  const handleShowSearchSettings = useCallback((event) => {
    event.stopPropagation();
    setShowBibleSearchSettings((prevState) => !prevState);
  }, []);

  return (
    <BibleSearchHeaderContainer>
      <BackToChapterButton onClick={onClickBackButton}>
        <LeftArrowIconStyled color="#383838" />
      </BackToChapterButton>

      <BibleSearchInputContainer
        inputIsEmpty={inputIsEmpty}
        onSubmit={onSearchSubmit}
      >
        <BibleSearchInput
          placeholder={"Поиск по Библии"}
          value={bibleSearchInput}
          onChange={handleSearchInput}
        />

        {showedInputIcon === "search_icon" && (
          <SearchIconContainer type="submit">
            <SearchIconStyled />
          </SearchIconContainer>
        )}

        {showedInputIcon === "remove_icon" && (
          <SearchIconContainer onClick={onRemoveIconClick} type="button">
            <RemoveIconStyled color="#383838" />
          </SearchIconContainer>
        )}
      </BibleSearchInputContainer>

      <SearchSettingsButton onClick={handleShowSearchSettings}>
        <BibleSearchSettingsIcon />
      </SearchSettingsButton>
    </BibleSearchHeaderContainer>
  );
};

export default memo(BibleSearchHeaderMobile);
