import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import axios from "../../../axios_config";
import { BASE_API_URL } from "../../../common/endpoints";
import ScrollToTopButton from "../../../common/components/scroll_to_top_button";
import AdminPageCoursesList from "./admin_page_courses_list";
import AdminPageCourseSettings from "./admin_page_course_settings";
import AdminPageCourseLessonsList from "./admin_page_course_lessons_list";
import AdminPageCourseLessonSettings from "./admin_page_course_lesson_settings";
import { useNavigate, useSearchParams } from "react-router-dom";
import { compareObjects } from "../../../common/utils/functions/compare_objects";
import UserInfoModalWindow from "../../../common/components/modal_windows/user_info_modal_window";
import { useUserDataContext } from "../../../user_data_context";

export const COURSE_COMPLEXITY_LEVELS = {
  basic: "Начальный",
  medium: "Средний",
  advanced: "Продвинутый",
};

const AdminPageCoursesWrapper = styled.div`
  position: relative;
  flex: 1;
`;

const AdminPageCoursesContainer = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
  height: calc(100vh - 80px);

  ::-webkit-scrollbar {
    width: 0px;
  }

  scrollbar-width: none;
`;

const AdminPageCourses = () => {
  const navigate = useNavigate();
  const coursesListRef = useRef(null);
  const lessonsListRef = useRef(null);

  const { userData, accessRights } = useUserDataContext();

  const [searchParams, setSearchParams] = useSearchParams();
  const courseId = searchParams.get("courseId");
  const lessonId = searchParams.get("lessonId");
  const lessonsPreviews = searchParams.get("lessons");

  const [allAuthorsList, setAllAuthorsList] = useState([]);
  const [coursesList, setCoursesList] = useState([]);
  const [activePreviewsBlock, setActivePreviewsBlock] = useState("courses"); // courses, lessons
  const [courseSettingsData, setCourseSettingsData] = useState({});
  const [courseSettingsMemoData, setCourseSettingsMemoData] = useState({});
  const [lessonSettingsData, setLessonSettingsData] = useState({});
  const [selectedUserInfo, setSelectedUserInfo] = useState({});
  const [openEditCourseModal, setOpenEditCourseModal] = useState(false);
  const [isLoadingCourses, setIsLoadingCourses] = useState(false);

  const hasAccess =
    accessRights.isSuperadmin ||
    accessRights.isAdminOfPlatformCourses ||
    accessRights.isAdminOfCourses ||
    accessRights.isAuthor;

  useEffect(() => {
    if (userData._id && !hasAccess) {
      navigate("/bible");
    }

    if (userData._id && hasAccess) {
      localStorage.setItem("lastAdminUrl", location.pathname);
    }
  }, [accessRights, userData]);

  const fetchCourses = useCallback(async () => {
    if (userData._id) {
      if (accessRights.isSuperadmin || accessRights.isAdminOfPlatformCourses) {
        try {
          const response = await axios.get(BASE_API_URL + "/courses");
          setCoursesList(response.data);
        } catch (error) {
          console.error("Ошибка при запросе:", error);
        }
      } else {
        try {
          const [creatorResponse, adminResponse, authorResponse] =
            await Promise.all([
              axios.get(`${BASE_API_URL}/courses?creatorId=${userData._id}`),
              axios.get(`${BASE_API_URL}/courses?adminId=${userData._id}`),
              axios.get(`${BASE_API_URL}/courses?authorId=${userData._id}`),
            ]);

          const combinedCourses = [
            ...creatorResponse.data,
            ...adminResponse.data,
            ...authorResponse.data,
          ];

          const uniqueCoursesMap = new Map();
          combinedCourses.forEach((course) =>
            uniqueCoursesMap.set(course._id, course)
          );

          const uniqueCourses = Array.from(uniqueCoursesMap.values()).sort(
            (a, b) =>
              new Date(b.course_created_at) - new Date(a.course_created_at)
          );

          setCoursesList(uniqueCourses);
        } catch (error) {
          console.error("Ошибка при запросе:", error);
        }
      }
    }
  }, [userData, accessRights]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingCourses(true);
      await fetchCourses();
      setIsLoadingCourses(false);
    };

    if (userData._id) {
      fetchData();
    }
  }, [userData]);

  const fetchCourse = useCallback(async () => {
    axios
      .get(BASE_API_URL + "/courses/" + courseId)
      .then((response) => {
        setCourseSettingsData(response.data);
        setCourseSettingsMemoData(response.data);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      });
  }, [courseId]);

  useEffect(() => {
    if (courseId) {
      fetchCourse();
    } else {
      setActivePreviewsBlock("courses");
    }
  }, [courseId]);

  useEffect(() => {
    if (
      courseSettingsData._id === "new_course" &&
      courseSettingsMemoData._id !== "new_course" &&
      courseSettingsData.about_course?.blocks?.length &&
      courseSettingsData.about_course?.entityMap
    ) {
      setCourseSettingsMemoData(courseSettingsData);
    }
  }, [courseSettingsData]);

  const fetchLesson = useCallback(async () => {
    axios
      .get(BASE_API_URL + "/lessons/" + lessonId)
      .then((response) => {
        setLessonSettingsData(response.data);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      });
  }, [lessonId]);

  useEffect(() => {
    if (lessonId || lessonsPreviews) {
      lessonId && fetchLesson();
      setActivePreviewsBlock("lessons");
    }
  }, [lessonId, lessonsPreviews]);

  useEffect(() => {
    const newCourse = coursesList?.[0]?._id === "new_course";

    if (!courseSettingsData._id && newCourse) {
      setCoursesList((prevState) =>
        prevState.filter((course) => course._id !== "new_course")
      );
    }
  }, [coursesList, courseSettingsData]);

  const closeCourseSettings = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("courseId");
    newSearchParams.delete("lessons");
    newSearchParams.delete("lessonId");
    setSearchParams(newSearchParams);

    setCourseSettingsData({});
    setCourseSettingsMemoData({});
    setLessonSettingsData({});
    setOpenEditCourseModal(false);
    fetchCourses();
  }, [searchParams, setSearchParams]);

  const onCloseCourseSettings = useCallback(() => {
    if (compareObjects(courseSettingsData, courseSettingsMemoData)) {
      closeCourseSettings();
    } else {
      setOpenEditCourseModal(true);
    }
  }, [courseSettingsData, courseSettingsMemoData]);

  const closeLessonSettings = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete("lessonId");
    setSearchParams(newSearchParams);

    setLessonSettingsData({});
  }, [searchParams, setSearchParams]);

  const fetchAllAuthors = useCallback(async () => {
    axios
      .get(BASE_API_URL + "/users")
      .then((response) => {
        const filteredUsers = response.data.filter((user) => {
          return user.user_name && user.user_access.is_author;
        });

        setAllAuthorsList(filteredUsers);
      })
      .catch((error) => {
        console.error("Ошибка при запросе:", error);
      });
  }, []);

  useEffect(() => {
    fetchAllAuthors();
  }, []);

  if (!hasAccess) return;

  return (
    <AdminPageCoursesWrapper>
      {activePreviewsBlock === "courses" && (
        <AdminPageCoursesContainer
          ref={coursesListRef}
          onMouseDown={onCloseCourseSettings}
        >
          <AdminPageCoursesList
            coursesList={coursesList}
            setCoursesList={setCoursesList}
            courseSettingsData={courseSettingsData}
            allAuthorsList={allAuthorsList}
            courseSettingsMemoData={courseSettingsMemoData}
            isLoadingCourses={isLoadingCourses}
            setActivePreviewsBlock={setActivePreviewsBlock}
            setCourseSettingsData={setCourseSettingsData}
            setCourseSettingsMemoData={setCourseSettingsMemoData}
            setOpenEditCourseModal={setOpenEditCourseModal}
            closeCourseSettings={closeCourseSettings}
            onCloseCourseSettings={onCloseCourseSettings}
            fetchCourses={fetchCourses}
          />

          <AdminPageCourseSettings
            activePreviewsBlock={activePreviewsBlock}
            courseSettingsData={courseSettingsData}
            courseSettingsMemoData={courseSettingsMemoData}
            setCourseSettingsData={setCourseSettingsData}
            setCourseSettingsMemoData={setCourseSettingsMemoData}
            setSelectedUserInfo={setSelectedUserInfo}
            openEditCourseModal={openEditCourseModal}
            setOpenEditCourseModal={setOpenEditCourseModal}
            closeCourseSettings={closeCourseSettings}
            fetchCourses={fetchCourses}
          />
        </AdminPageCoursesContainer>
      )}

      {activePreviewsBlock === "lessons" && (
        <AdminPageCoursesContainer
          ref={lessonsListRef}
          onMouseDown={closeLessonSettings}
        >
          <AdminPageCourseLessonsList
            courseSettingsData={courseSettingsData}
            lessonSettingsData={lessonSettingsData}
            setActivePreviewsBlock={setActivePreviewsBlock}
            setLessonSettingsData={setLessonSettingsData}
            closeLessonSettings={closeLessonSettings}
            fetchCourses={fetchCourses}
            fetchCourse={fetchCourse}
          />

          <AdminPageCourseLessonSettings
            lessonSettingsData={lessonSettingsData}
            setLessonSettingsData={setLessonSettingsData}
            fetchCourse={fetchCourse}
            fetchLesson={fetchLesson}
          />
        </AdminPageCoursesContainer>
      )}

      {selectedUserInfo._id && (
        <UserInfoModalWindow
          selectedUserInfo={selectedUserInfo}
          setSelectedUserInfo={setSelectedUserInfo}
        />
      )}

      <ScrollToTopButton
        parentRef={
          activePreviewsBlock === "courses" ? coursesListRef : lessonsListRef
        }
      />
    </AdminPageCoursesWrapper>
  );
};

export default memo(AdminPageCourses);
