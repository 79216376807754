import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { BASE_API_URL } from "../../../../../../common/endpoints";
import { isCombinedVerses } from "../../../../../../common/utils/functions/bible_page_functions";
import TranslationCompareItemHeader from "./translation_compare_item_header";
import FramedBibleVerse from "../../../../../../common/components/framed_bible_verse";
import { useSearchParams } from "react-router-dom";

const TranslationCompareItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TranslationCompareItem = ({
  translation,
  translationIndex,
  selectedVerses,
  setComparedTranslationsList,
  setSelectTranslationData,
  onCloseBibleCompare,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [translationData, setTranslationData] = useState({});

  const selectedVersesNumbers = selectedVerses?.verses?.map(
    (verse) => verse.verse_num
  );

  const fetchTranslationVersesData = useCallback(
    async (translation) => {
      try {
        const response = await axios.get(`${BASE_API_URL}/bible/verses`, {
          params: {
            bible: translation.bible_code,
            book: selectedVerses.verses?.[0]?.book_code,
            chapter: selectedVerses.verses?.[0].chapter_num,
          },
        });
        const data = response.data;
        const filteredVerses = data.verses.filter((verse) =>
          selectedVersesNumbers.includes(verse.verse_num)
        );

        const comparedVerseData = {
          lang: translation.bible_locale,
          bible_code: translation.bible_code,
          bible_name: translation.bible_name,
          verses: isCombinedVerses(filteredVerses, translation.bible_locale),
        };

        setTranslationData(comparedVerseData);

        setComparedTranslationsList((prevState) => {
          return prevState.map((transl, index) =>
            index === translationIndex ? translation : transl
          );
        });
      } catch (error) {
        console.error(error);
      }
    },
    [translationIndex, translation, selectedVerses, selectedVersesNumbers]
  );

  useEffect(() => {
    if (translation?.bible_code) {
      fetchTranslationVersesData(translation);
    }
  }, [translation]);

  const onBibleCompareAddress = useCallback(
    (bibleId) => {
      const selectedVersesNums = selectedVersesNumbers.join(",");

      setSearchParams((prev) => {
        prev.set("bibleId", bibleId);
        prev.set("selectBible", bibleId);
        prev.set("selectVerses", selectedVersesNums);
        return prev;
      });

      onCloseBibleCompare();
    },
    [onCloseBibleCompare, setSearchParams]
  );

  return (
    <TranslationCompareItemContainer>
      <TranslationCompareItemHeader
        translation={translation}
        translationIndex={translationIndex}
        setComparedTranslationsList={setComparedTranslationsList}
        setSelectTranslationData={setSelectTranslationData}
        onCloseBibleCompare={onCloseBibleCompare}
      />

      <FramedBibleVerse
        verse={translationData}
        background={"#eaeaea"}
        inComponent={"bible_compare"}
        onBibleCompareAddress={onBibleCompareAddress}
      />
    </TranslationCompareItemContainer>
  );
};

export default memo(TranslationCompareItem);
