import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import TranslationCompareItem from "./translation_compare_item";
import { useBiblePageContext } from "../../../bible_page_context";
import { USER_LANGUAGE } from "../../../bible_page_data";
import CompareTranslationSelect from "./translation_compare_item/compare_translation_select";
import SpinnerLoader from "../../../../../common/components/loaders/spinner_loader";

const BibleCompareMobileContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 20px 12px;
  background: #eaeaea;
  overflow: auto;

  ::-webkit-scrollbar {
    width: 3px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const BibleCompareMobile = ({
  selectedVerses,
  bibleTranslations,
  setBibleTranslations,
}) => {
  const { setActiveScreenMobile } = useBiblePageContext();

  const [isLoading, setIsLoading] = useState(true);
  const [comparedTranslationsList, setComparedTranslationsList] = useState([]);
  const [selectTranslationData, setSelectTranslationData] = useState({});

  useEffect(() => {
    if (bibleTranslations?.length) {
      const filteredBibleTranslations = bibleTranslations.filter(
        (translation) =>
          translation.bible_locale === USER_LANGUAGE || translation.isFavorite
      );
      filteredBibleTranslations?.sort((a, b) => b.isFavorite - a.isFavorite);
      setComparedTranslationsList(filteredBibleTranslations);
    }

    return () => {
      setComparedTranslationsList([]);
    };
  }, [bibleTranslations]);

  useEffect(() => {
    if (comparedTranslationsList.length) {
      setIsLoading(false);
    }
  }, [comparedTranslationsList]);

  const onCloseBibleCompare = useCallback(() => {
    setActiveScreenMobile("bible_chapter");
  }, []);

  return (
    <BibleCompareMobileContainer>
      {isLoading ? (
        <LoaderContainer>
          <SpinnerLoader />
        </LoaderContainer>
      ) : (
        <>
          {comparedTranslationsList.map((translation, index) => {
            return (
              <TranslationCompareItem
                key={translation.bible_code + index}
                translation={translation}
                translationIndex={index}
                selectedVerses={selectedVerses}
                setComparedTranslationsList={setComparedTranslationsList}
                setSelectTranslationData={setSelectTranslationData}
                onCloseBibleCompare={onCloseBibleCompare}
              />
            );
          })}
        </>
      )}

      {!!selectTranslationData?.type && (
        <CompareTranslationSelect
          selectTranslationData={selectTranslationData}
          comparedTranslationsList={comparedTranslationsList}
          setSelectTranslationData={setSelectTranslationData}
          bibleTranslations={bibleTranslations}
          setBibleTranslations={setBibleTranslations}
          setComparedTranslationsList={setComparedTranslationsList}
        />
      )}
    </BibleCompareMobileContainer>
  );
};

export default memo(BibleCompareMobile);
