import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import BibleTestamentButtons from "./bible_testament_buttons";
import BookNavigation from "./book_navigation";
import SimpleListLoader from "./simple_list_loader";
import { BASE_API_URL } from "../../../../../common/endpoints";
import { BIBLE_BOOK_NAMES_DATA } from "../../../bible_page_data";
import { useBiblePageContext } from "../../../bible_page_context";
import RemoveIcon from "../../../../../common/assets/icons/remove_icon";
import useIsMobile from "../../../../../common/hooks/use_is_mobile";
import axios from "axios";

const BookListNavigationWrapper = styled.div`
  overflow: hidden;
  background: #fff;
  border-radius: 15px;

  &: hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
  }

  @media (max-width: 744px) {
    height: 100%;
    border-radius: 0;
    background: #eaeaea;

    &: hover {
      box-shadow: none;
    }
  }
`;

const BookListNavigationHeader = styled.div`
  width: 100%;

  @media (max-width: 744px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
    padding: 0 7px 0 14px;
  }
`;

const BookListNavigationContainer = styled.div`
  padding: 7px 10px 8px 12px;
  overflow-y: auto;
  max-height: calc(100% - 30px);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12);

  ::-webkit-scrollbar-track {
    background-color: #fff;
  }

  ::-webkit-scrollbar {
    width: 2px;
  }

  @media (max-width: 744px) {
    box-shadow: none;
    padding: 4px 7px 20px 16px;
    max-height: calc(100% - 46px);

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  @media (max-width: 412px) {
    max-height: calc(100% - 66px);
  }
`;

const CloseBibleNavButton = styled.div`
  padding: 6px 10px 4px;
`;

const RemoveIconStyled = styled(RemoveIcon)`
  width: 20px;
  height: 20px;
`;

const BookListNavigation = ({
  currentTranslation,
  activeScreenDesktop,
  expandedSectionName,
}) => {
  const [searchParams] = useSearchParams();
  const scrollContainerRef = useRef(null);

  const {
    currentTranslationData,
    setCurrentTranslationData,
    setActiveScreenMobile,
  } = useBiblePageContext();

  const [expandedBookID, setExpandedBookID] = useState("");
  const [selectedTestament, setSelectedTestament] = useState("ot"); // ot, nt
  const [sortedBooksByTestaments, setSortedBooksByTestaments] = useState([]);
  const [isTranslationLoading, setTranslationLoading] = useState(false);

  const bibleBookId = searchParams.get("bookId");

  const isMobile = useIsMobile(744);

  const handleTestaments = useCallback((part) => {
    setSelectedTestament(part);
  }, []);

  useEffect(() => {
    const currentTestament = currentTranslationData?.find(
      (book) => book.book_code === bibleBookId
    );

    currentTestament && setSelectedTestament(currentTestament.book_place);
  }, [currentTranslationData, bibleBookId]);

  useEffect(() => {
    if (bibleBookId) {
      setExpandedBookID(bibleBookId);
    }
  }, [bibleBookId]);

  useEffect(() => {
    if (currentTranslationData) {
      const sortByTestaments = currentTranslationData.filter(
        (book) => book.book_place === selectedTestament
      );
      setSortedBooksByTestaments(sortByTestaments);
    }
  }, [currentTranslationData, selectedTestament]);

  useEffect(() => {
    if (currentTranslation?.bible_code) {
      const fetchBooks = async () => {
        setTranslationLoading(true);
        try {
          const response = await axios.get(
            `${BASE_API_URL}/bible/books?bible=${currentTranslation.bible_code}`
          );

          const bibleData = response.data;

          const updatedBooksNames = bibleData.books.map((book) => {
            if (BIBLE_BOOK_NAMES_DATA[currentTranslation.bible_locale]) {
              return {
                ...book,
                book_name:
                  BIBLE_BOOK_NAMES_DATA[currentTranslation.bible_locale][
                    book.book_code
                  ].name,
                number:
                  BIBLE_BOOK_NAMES_DATA[currentTranslation.bible_locale][
                    book.book_code
                  ].number,
              };
            } else {
              return book;
            }
          });

          const sortedBooks = updatedBooksNames.sort(
            (a, b) => a.number - b.number
          );

          setCurrentTranslationData(sortedBooks);

          if (bibleBookId) {
            setExpandedBookID(bibleBookId);
          }
        } catch (error) {
          console.error("Ошибка при загрузке книг Библии:", error);
        } finally {
          setTranslationLoading(false);
        }
      };

      fetchBooks();
    }
  }, [currentTranslation]);

  const onClickCloseNavButton = useCallback((event) => {
    event.stopPropagation();
    setActiveScreenMobile("bible_chapter");
  }, []);

  return (
    <BookListNavigationWrapper>
      <BookListNavigationHeader>
        <BibleTestamentButtons
          selectedTestament={selectedTestament}
          activeScreenDesktop={activeScreenDesktop}
          handleTestaments={handleTestaments}
        />

        {isMobile && (
          <CloseBibleNavButton onClick={onClickCloseNavButton}>
            <RemoveIconStyled color="#383838" />
          </CloseBibleNavButton>
        )}
      </BookListNavigationHeader>

      <>
        {!isTranslationLoading ? (
          <BookListNavigationContainer ref={scrollContainerRef}>
            {currentTranslationData ? (
              sortedBooksByTestaments.map((book) => (
                <BookNavigation
                  key={book.book_code}
                  book={book}
                  scrollContainerRef={scrollContainerRef}
                  isExpanded={expandedBookID === book.book_code}
                  expandedSectionName={expandedSectionName}
                  setIsActive={setExpandedBookID}
                />
              ))
            ) : (
              <div>Пожалуйста, выберите перевод Библии в секции переводов.</div>
            )}
          </BookListNavigationContainer>
        ) : (
          <SimpleListLoader />
        )}
      </>
    </BookListNavigationWrapper>
  );
};

export default memo(BookListNavigation);
