import React from "react";

const CheckIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_5915_7221)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.05013 1.98409C9.24839 2.17773 9.24839 2.49167 9.05013 2.68531L3.97321 7.64364C3.77494 7.83728 3.45349 7.83728 3.25522 7.64364L0.947528 5.38985C0.749262 5.19622 0.749262 4.88227 0.947528 4.68864C1.14579 4.495 1.46725 4.495 1.66551 4.68864L3.61421 6.59182L8.33214 1.98409C8.53041 1.79046 8.85186 1.79046 9.05013 1.98409Z"
          fill="#383838"
        />
      </g>
      <defs>
        <clipPath id="clip0_5915_7221">
          <rect
            width="8.4"
            height="8.4"
            fill="white"
            transform="translate(0.798828 0.799805)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CheckIcon;
