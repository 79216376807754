import React from "react";
import styled from "styled-components";
import VerticalLine from "../../../common/assets/icons/vertical_line";

const BLOCK_VARIANT = {
  TITLE: {
    LARGE_SIZE: `
        font-weight: 600;
        color: #cacaca;

        @media (max-width: 1024px) {
         font-weight: 400;
         letter-spacing: 0;
        }

        @media (max-width: 744px) {
         line-height: 130%;
        }

        @media (max-width: 412px) {
         font-size: 10px;
         letter-spacing: -0.1px;
         line-height: 135%;
        }
      `,
    SMALL_SIZE: `
        font-weight: 400;
        color: #828282;

        @media (max-width: 412px) {
         letter-spacing: 0;
        }
      `,
  },
  SUBTITLE: {
    LARGE_SIZE: `
     font-size: 28px;
     line-height: 30px;

     @media (max-width: 1024px) {
      font-size: 20px;
      font-weight: 600;
      line-height: 140%;
     }

     @media (max-width: 880px) {
      font-size: 18px;
    }

     @media (max-width: 800px) {
      font-size: 16px;
      letter-spacing: 0.24px;
      line-height: 145%;
    }

     @media (max-width: 744px) {
      line-height: 100%;
    }
   `,
    SMALL_SIZE: `
     font-size: 20px;
     line-height: 28px;

     @media (max-width: 412px) {
      font-size: 18px;
      line-height: 140%;
     }
   `,
  },
};

const RegistEndsAndPlacesInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => (size === "LARGE_SIZE" ? "73px" : "")};

  @media (max-width: 1024px) {
    height: ${({ size }) => (size === "LARGE_SIZE" ? "60px" : "")};
  }

  @media (max-width: 800px) {
    height: ${({ size }) => (size === "LARGE_SIZE" ? "50px" : "")};
  }

  @media (max-width: 744px) {
    height: ${({ size }) => (size === "LARGE_SIZE" ? "44px" : "")};
  }

  @media (max-width: 670px) {
    height: ${({ size }) => (size === "LARGE_SIZE" ? "50px" : "")};
  }

  @media (max-width: 412px) {
    height: ${({ size }) => (size === "LARGE_SIZE" ? "48px" : "")};
  }
`;

const RegistEndsAndPlacesInfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const RegistEndsAndPlacesInfoTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  ${({ size }) =>
    size === "LARGE_SIZE"
      ? BLOCK_VARIANT.TITLE.LARGE_SIZE
      : BLOCK_VARIANT.TITLE.SMALL_SIZE}
`;

const RegistEndsAndPlacesInfoNumbers = styled.p`
  font-weight: 700;
  color: #f8d254;
  ${({ size }) =>
    size === "LARGE_SIZE"
      ? BLOCK_VARIANT.SUBTITLE.LARGE_SIZE
      : BLOCK_VARIANT.SUBTITLE.SMALL_SIZE}
`;

const VerticalLineStyles = styled(VerticalLine)`
  height: ${({ size }) => (size === "LARGE_SIZE" ? "32px" : "36px")};

  @media (max-width: 1024px) {
    ${({ size }) => (size === "LARGE_SIZE" ? "height: 28px;" : "")}
  }

  @media (max-width: 744px) {
    ${({ size }) => (size === "LARGE_SIZE" ? "height: 20px;" : "")}
  }
`;

const RegistEndsAndPlacesInfo = ({ blockVariant }) => {
  return (
    <RegistEndsAndPlacesInfoContainer size={blockVariant}>
      <RegistEndsAndPlacesInfoBlock>
        <RegistEndsAndPlacesInfoTitle size={blockVariant}>
          До конца регистрации:
        </RegistEndsAndPlacesInfoTitle>
        <RegistEndsAndPlacesInfoNumbers size={blockVariant}>
          10:05:35
        </RegistEndsAndPlacesInfoNumbers>
      </RegistEndsAndPlacesInfoBlock>

      <div>
        <VerticalLineStyles size={blockVariant} />
      </div>

      <RegistEndsAndPlacesInfoBlock>
        <RegistEndsAndPlacesInfoTitle size={blockVariant}>
          Мест осталось:
        </RegistEndsAndPlacesInfoTitle>
        <RegistEndsAndPlacesInfoNumbers size={blockVariant}>
          19
        </RegistEndsAndPlacesInfoNumbers>
      </RegistEndsAndPlacesInfoBlock>
    </RegistEndsAndPlacesInfoContainer>
  );
};

export default RegistEndsAndPlacesInfo;
