import React from "react";
import styled from "styled-components";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";
import { useAuthRedirect } from "../../../common/hooks/use_auth_redirect";

const StartStudyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 540px;
  gap: 15px;
  min-width: 47%;

  @media (max-width: 744px) {
    gap: 34px;
    align-items: center;
    padding-bottom: 13px;
  }
`;

const StartStudyTitle = styled.p`
  font-family: Raleway;
  font-weight: 400;
  font-size: 54px;
  line-height: 64px;
  letter-spacing: -1.2px;
  color: #1e1e1e;

  & span {
    font-family: Raleway;
    font-weight: 700;
  }

  @media (max-width: 1024px) {
    font-size: 43px;
    line-height: 54px; /* 125.581% */
    letter-spacing: -0.5px;
  }
`;

const StartStudyDescription = styled.p`
  max-width: 70%;
  font-family: Inter;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #1e1e1e;

  @media (max-width: 1024px) {
    font-size: 18px;
    font-weight: 400;
    line-height: 120%;
    max-width: 80%;
  }

  @media (max-width: 744px) {
    max-width: 100%;
  }
`;

const StartButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  min-height: 70px;
  width: max-content;
  padding: 0 50px;
  border-radius: 15px;
  background: linear-gradient(180deg, #5172ea -18.38%, #7a5aee 108.82%);
  box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  font-family: Geometria;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    box-shadow: 4.462px 4.462px 7.808px 0px rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 744px) {
    width: 100%;
  }
`;

const HomePageStartStudy = () => {
  const redirectToAuth = useAuthRedirect();

  const onClickStartButton = () => {
    redirectToAuth(APPLICATION_ROUTES.STUDY_COURSES);
  };

  return (
    <StartStudyContainer>
      <StartStudyTitle>
        Исследуй Слово Божье c <span>On Bible</span>
      </StartStudyTitle>

      <StartStudyDescription>
        Зарегистрируйся или войди, чтобы пользоваться группами и изучать курсы
      </StartStudyDescription>

      <StartButton onClick={onClickStartButton}>Начать</StartButton>
    </StartStudyContainer>
  );
};

export default HomePageStartStudy;
