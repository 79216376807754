import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "./axios_config";
import { BASE_API_URL } from "./common/endpoints";

const UserDataContext = createContext();

const defaultAccessRights = {
  isSuperadmin: false,
  isAdminOfCourses: false,
  isAdminOfGroups: false,
  isAuthor: false,
  isAdminOfPlatformUsers: false,
  isAdminOfPlatformCourses: false,
  isAdminOfPlatformGroups: false,
  isAdminOfPlatformQuestions: false,
  isAdminOfPlatformAnalytics: false,
  isAdminOfPlatformHomepage: false,
  isMentor: false,
  isLeader: false,
  isOperatorOfSupport: false,
};

export const useUserDataState = () => {
  const [userData, setUserData] = useState({});
  const [authError, setAuthError] = useState(null);
  const [accessRights, setAccessRights] = useState(defaultAccessRights);
  const [userLanguage, setUserLanguage] = useState("ru");

  useEffect(() => {
    const language = navigator.language || navigator.userLanguage;

    if (language.startsWith("uk")) {
      setUserLanguage("uk");
    } else if (language.startsWith("ru")) {
      setUserLanguage("ru");
    } else {
      setUserLanguage("en");
    }
  }, []);

  const defineAccessRights = (userData) => {
    const isAdminOfPlatform = userData.user_access?.is_admin_of_platform;

    setAccessRights({
      ...defaultAccessRights,
      isSuperadmin: userData.user_role === "superadmin",
      isAdminOfCourses: userData.user_access?.is_admin_of_courses,
      isAdminOfGroups: userData.user_access?.is_admin_of_groups,
      isAuthor: userData.user_access?.is_author,
      isAdminOfPlatform,
      isAdminOfPlatformUsers:
        isAdminOfPlatform && userData.user_access?.admin_of_platform?.users,
      isAdminOfPlatformCourses:
        isAdminOfPlatform && userData.user_access?.admin_of_platform?.courses,
      isAdminOfPlatformGroups:
        isAdminOfPlatform && userData.user_access?.admin_of_platform?.groups,
      isAdminOfPlatformQuestions:
        isAdminOfPlatform && userData.user_access?.admin_of_platform?.questions,
      isAdminOfPlatformAnalytics:
        isAdminOfPlatform && userData.user_access?.admin_of_platform?.analytics,
      isAdminOfPlatformHomePage:
        isAdminOfPlatform && userData.user_access?.admin_of_platform?.homepage,
      isMentor: userData.user_access?.is_mentor,
      isLeader: userData.user_access?.is_leader,
      isOperatorOfSupport: userData.user_access?.is_operator_of_support,
    });
  };

  const fetchUserData = async () => {
    const token = localStorage.getItem("access_token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }

    try {
      const response = await axios.get(`${BASE_API_URL}/auth/profile`);
      const user = response.data.user;
      setUserData(user);
      defineAccessRights(user);
      setAuthError(null);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setUserData({});
      setAuthError(
        error.response?.data?.message || "Error fetching user data."
      );
    }
  };

  return {
    userData,
    accessRights,
    fetchUserData,
    authError,
    userLanguage,
  };
};

export const UserDataProvider = ({ children }) => {
  const { userData, fetchUserData, authError, accessRights, userLanguage } =
    useUserDataState();

  return (
    <UserDataContext.Provider
      value={{
        userData,
        fetchUserData,
        authError,
        accessRights,
        userLanguage,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserDataContext = () => {
  return useContext(UserDataContext);
};
