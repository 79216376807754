import React, { useCallback, useMemo, useRef, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import styled from "styled-components";
import { AVAILABLE_SYSTEM_LANGUAGES } from "../../../utils/enums";
import { LanguageIcon as DefaultLanguageIcon } from "../../../assets/icons";

const LanguageSelectorContainer = styled.div`
  position: relative;
`;

const LanguageIconContainer = styled.div`
  cursor: pointer;
  display: flex;
`;

const LanguageIcon = styled(DefaultLanguageIcon)`
  stroke: ${({ active }) => (active ? "#1E1E1E" : "#CACACA")};
`;

const LanguageMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  background: #fff;
  padding: 24px 25px;
  top: calc(100% + 35px);
  left: -25px;
  border-radius: 15px;

  @media (max-width: 1024px) {
    left: auto;
    top: -12px;
    flex-direction: row;
    right: 60px;
    padding: 14px 25px;
    border-radius: 20px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);
  }
`;

const LanguageMenuItem = styled.div`
  cursor: pointer;
  font-size: 20px;
  color: ${({ active }) => (active ? "#1E1E1E" : "#CACACA")};

  @media (max-width: 1024px) {
    border-left: 1px solid rgb(202 202 202 / 52%);
    padding-left: 12px;

    &:first-child {
      border: none;
      padding-left: 0;
    }
  }
`;

const HeaderLanguageSelector = ({ activeLanguage = "RU" }) => {
  const langMenuRef = useRef();
  const [isLangMenuOpened, setIsLangMenuOpened] = useState(false);

  const languageIconClickHandler = useCallback(
    () => setIsLangMenuOpened(!isLangMenuOpened),
    [isLangMenuOpened]
  );

  useOnclickOutside(() => setIsLangMenuOpened(false), { refs: [langMenuRef] });

  const availableSystemLanguages = useMemo(
    () => [
      AVAILABLE_SYSTEM_LANGUAGES.UA,
      AVAILABLE_SYSTEM_LANGUAGES.EN,
      AVAILABLE_SYSTEM_LANGUAGES.RU,
    ],
    []
  );

  return (
    <LanguageSelectorContainer>
      <LanguageIconContainer onClick={languageIconClickHandler}>
        <LanguageIcon />
      </LanguageIconContainer>
      {isLangMenuOpened && (
        <LanguageMenuContainer ref={langMenuRef}>
          {availableSystemLanguages.map((lang) => (
            <LanguageMenuItem
              key={lang.value}
              active={lang.value === activeLanguage}
            >
              {lang.label}
            </LanguageMenuItem>
          ))}
        </LanguageMenuContainer>
      )}
    </LanguageSelectorContainer>
  );
};

export default HeaderLanguageSelector;
