import React, { memo, useCallback, useEffect, useState } from "react";
import TranlationPanelInput from "../../../bible_navigation_panel_desktop/bible_translations_panel/translation_panel_input";
import TranslationsList from "../../../bible_navigation_panel_desktop/bible_translations_panel/translations_list";

const ComparedTranslationsList = ({
  bibleTranslations,
  currentBibleLanguage,
  translation,
  setCurrentScreen,
  setBibleTranslations,
  onTranslationClick,
}) => {
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [selectedTranslations, setSelectedTranslations] = useState([]);

  const getFilteredTranslation = useCallback(
    (bibleTranslations, inputSearchValue, currentBibleLanguage) => {
      const sortedTranslationByFavorite = bibleTranslations?.sort(
        (tr1, tr2) => tr2.isFavorite - tr1.isFavorite
      );
      const filteredByLanguage = sortedTranslationByFavorite?.filter(
        (translation) =>
          translation.bible_locale === currentBibleLanguage.code ||
          translation.isFavorite
      );

      const filteredBySearch = filteredByLanguage?.filter(
        (translation) =>
          translation.bible_code
            .toLowerCase()
            .includes(inputSearchValue.toLowerCase()) ||
          translation.bible_name
            .toLowerCase()
            .includes(inputSearchValue.toLowerCase()) ||
          translation.isFavorite
      );

      return filteredBySearch;
    },
    []
  );

  useEffect(() => {
    if (bibleTranslations) {
      setSelectedTranslations(
        getFilteredTranslation(
          bibleTranslations,
          inputSearchValue,
          currentBibleLanguage
        )
      );
    }
  }, [bibleTranslations, inputSearchValue, currentBibleLanguage]);

  return (
    <div>
      <TranlationPanelInput
        placeholder={"Поиск перевода..."}
        currentBibleLanguage={currentBibleLanguage}
        section={"translation"}
        onClickButton={() => setCurrentScreen("languages")}
        inputSearchValue={inputSearchValue}
        setInputSearchValue={setInputSearchValue}
      />
      <TranslationsList
        selectedTranslations={selectedTranslations}
        currentTranslation={translation}
        currentBibleScreen="bible_compare"
        setBibleTranslations={setBibleTranslations}
        onTranslationClick={onTranslationClick}
      />
    </div>
  );
};

export default memo(ComparedTranslationsList);
