import React, { useCallback, useEffect, useRef, memo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const VerseContainer = styled.div`
  display: flex;
  gap: 3px;
  padding: 0 12px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 8px;
  min-height: ${({ blockHeight }) =>
    blockHeight ? blockHeight + "px" : "auto"};
  background: ${({ isSelected }) => (isSelected ? "#cacaca" : "none")};
  user-select: none;

  &:hover {
    background: ${({ isSelected }) =>
      isSelected ? "#cacaca" : "rgb(223 223 223)"};
    transition: 0.2s;
  }

  @media (max-width: 744px) {
    padding: 0 4px 0 6px;
    margin-right: 6px;
  }
`;

const VerseTextContainer = styled.div`
  flex: 1;
  height: 100%;
`;

const VerseText = styled.div`
  & > span {
    font-family: ${({ fontFamily }) => fontFamily} !important;
    font-size: ${({ fontSize }) => fontSize}px;
    line-height: ${({ fontSize }) => (fontSize ? fontSize * 1.3 : "24")}px;
    color: #1e1e1e;
  }
`;

const VerseNumber = styled.div`
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize}px;
  line-height: ${({ fontSize }) => (fontSize ? fontSize * 1.3 : "24")}px;
  color: #1e1e1e;
`;

const Verse = ({
  verse,
  verseIndex,
  bibleFontSettings,
  selectedVerses,
  setVerseHeights,
  blockHeight,
  isFirstTranslation,
  verseHeightsFirstTransl,
  verseHeightsSecondTransl,
}) => {
  if (!verse.verse_num) {
    return;
  }

  const verseRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [verseIsSelected, setVerseIsSelected] = useState(false);

  const bibleId = searchParams.get("bibleId");
  const bibleId2 = searchParams.get("2bibleId");
  const bookId = searchParams.get("bookId");
  const chapterId = searchParams.get("chapterId");

  useEffect(() => {
    const includes = selectedVerses?.verses?.filter(
      (obj) => obj.verse_num === verse.verse_num
    ).length;

    if (includes && verse.bible_code === selectedVerses.bible) {
      setVerseIsSelected(true);
    } else setVerseIsSelected(false);
  }, [selectedVerses, verse]);

  const handleVerseHeight = useCallback(() => {
    if (verseRef.current) {
      setVerseHeights((prevHeights) => {
        const newState = [...prevHeights];
        newState[verseIndex] = verseRef.current.offsetHeight;
        return newState;
      });
    }
  }, [verseRef.current, verseIndex]);

  useEffect(() => {
    if (!isFirstTranslation) {
      handleVerseHeight();
    }
  }, [verseHeightsFirstTransl]);

  useEffect(() => {
    if (
      isFirstTranslation &&
      verseHeightsFirstTransl.length === 0 &&
      verseHeightsSecondTransl.length > 0
    ) {
      handleVerseHeight();
    }
  }, [verseHeightsSecondTransl]);

  useEffect(() => {
    handleVerseHeight();
  }, [verseRef.current, bibleId, bibleId2, bookId, chapterId, verse._id]);

  const handleSelectVerses = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (
      selectedVerses?.verses?.length &&
      selectedVerses.bible === verse.bible_code
    ) {
      const selectedVerseNums = selectedVerses.verses
        .map((verse) => verse.verse_num)
        .sort((a, b) => a - b);
      const firstNum = selectedVerseNums[0];
      const lastNum = selectedVerseNums[selectedVerseNums.length - 1];

      if (!selectedVerseNums.includes(verse.verse_num)) {
        if (verse.verse_num === firstNum - 1) {
          newSearchParams.set(
            "selectVerses",
            [verse.verse_num, ...selectedVerseNums].toString()
          );
        } else if (verse.verse_num === lastNum + 1) {
          newSearchParams.set(
            "selectVerses",
            [...selectedVerseNums, verse.verse_num].toString()
          );
        } else {
          newSearchParams.set("selectVerses", verse.verse_num.toString());
        }
      } else {
        if (verse.verse_num === firstNum || verse.verse_num === lastNum) {
          const withoutCurrentVerse = selectedVerseNums
            .filter((num) => num !== verse.verse_num)
            .sort();
          if (withoutCurrentVerse.length) {
            newSearchParams.set("selectVerses", withoutCurrentVerse.toString());
          } else {
            newSearchParams.delete("selectVerses");
            newSearchParams.delete("selectBible");
          }
        } else {
          newSearchParams.delete("selectVerses");
          newSearchParams.delete("selectBible");
        }
      }
    } else {
      newSearchParams.set("selectVerses", verse.verse_num.toString());
      newSearchParams.set("selectBible", verse.bible_code.toString());
    }

    setSearchParams(newSearchParams);
  }, [searchParams, selectedVerses, verse]);

  useEffect(() => {
    if (verseIsSelected && verseRef.current) {
      verseRef.current.scrollIntoView({ block: "center" });
    }
  }, [verseRef.current, blockHeight]);

  return (
    <VerseContainer
      ref={verseRef}
      isSelected={verseIsSelected}
      blockHeight={blockHeight}
      onClick={handleSelectVerses}
    >
      <VerseNumber
        fontFamily={bibleFontSettings.fontVariant}
        fontSize={bibleFontSettings.fontSize}
      >
        {verse.verse_num}.
      </VerseNumber>
      <VerseTextContainer>
        <VerseText
          fontFamily={bibleFontSettings.fontVariant}
          fontSize={bibleFontSettings.fontSize}
        >
          {verse.verse_text}
        </VerseText>
      </VerseTextContainer>
    </VerseContainer>
  );
};

export default memo(Verse);
