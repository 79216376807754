import React from "react";
import styled from "styled-components";

const TextInputContainer = styled.div`
  position: relative;
`;

export const TextInput = styled.input`
  height: 52px;
  width: 100%;
  padding: 0 15px;
  ${({ isPassword }) => isPassword && "padding-right: 40px;"}
  border-radius: 10px;
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  transition: 0.2s;

  &:focus,
  :hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
`;

const ValidationMessageContainer = styled.div`
  position: absolute;
  bottom: -15px;
  ${({ leftHandMessage }) => (leftHandMessage ? "left: 0;" : "right: 0;")}
  color: #ef6f6f;
  font-size: 11px;
  z-index: 2;
`;

const RequireIcon = styled.div`
  position: absolute;
  top: -8px;
  right: -1px;
  color: #5172ea;
  font-weight: 600;
  user-select: none;
`;

const AuthTextInput = ({
  validationMessage = "",
  isPassword = false,
  leftHandMessage = false,
  require = true,
  ...props
}) => {
  return (
    <TextInputContainer>
      <TextInput {...props} isPassword={isPassword} autoComplete="off" />
      {require && <RequireIcon>*</RequireIcon>}

      {!!validationMessage && (
        <ValidationMessageContainer leftHandMessage={leftHandMessage}>
          {validationMessage}
        </ValidationMessageContainer>
      )}
    </TextInputContainer>
  );
};

export default AuthTextInput;
