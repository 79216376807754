import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import HeartIcon from "../../../common/assets/icons/donate_heart_icon";
import { APPLICATION_ROUTES } from "../../../common/utils/routes";

const ToDonatePageWrapper = styled.div`
  display: none;
  width: 100%;
  height: 86px;
  padding-top: 46px;

  @media (max-width: 1024px) {
    display: block;
  }

  @media (max-width: 744px) {
    height: 80px;
    padding-top: 48px;
  }

  @media (max-width: 412px) {
    height: 80px;
    padding-top: 46px;
    height: 73px;
  }
`;

const ToDonatePageContainer = styled.div`
  display: flex;
  background: linear-gradient(200deg, #05baa4 0%, #288bce 100%);
  width: 100%;
  height: 40px;
  color: #ffffff;
  font-size: 18px;
  line-height: 26px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  @media (max-width: 744px) {
    height: 32px;
  }

  @media (max-width: 412px) {
    height: 27px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.14px;
    gap: 5px;
  }
`;

const ToDonatePage = () => {
  return (
    <ToDonatePageWrapper>
      <Link to={APPLICATION_ROUTES.DONATE}>
        <ToDonatePageContainer>
          <p>Поддержи нас</p>
          <HeartIcon />
        </ToDonatePageContainer>
      </Link>
    </ToDonatePageWrapper>
  );
};

export default ToDonatePage;
