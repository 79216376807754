import React, { memo, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppContext } from "../../../../../app_context";

const FONTS_VARIANTS = [
  { name: "Inter", sizes: [10, 12, 14, 16, 20, 24] },
  { name: "PT Serif", sizes: [10, 12, 14, 16, 20, 24] },
  { name: "Ysabeau Infant", sizes: [11, 14, 16, 18, 22, 26] },
];

const FontSettingsBlockContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 74px;
  padding: 10px 14px;
  background: #fff;
  color: #000;
  border-radius: 15px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);

  @media (max-width: 744px) {
    box-shadow: 4.462px 4.462px 10px 0px rgba(0, 0, 0, 0.12);
  }
`;

const FontVariantButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 19px;
`;

const FontVariantButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 65px;
  padding: 0 12px;
  border-radius: 6px;
  cursor: pointer;
  font-family: ${({ fontVariant }) => fontVariant};
  font-size: ${({ fontVariant }) =>
    fontVariant === "Ysabeau Infant" ? "11px" : "10px"};
  line-height: 110%;
  letter-spacing: -0.1px;

  background: ${({ isSelected }) =>
    isSelected
      ? ` linear-gradient(
   180deg,
   rgba(81, 114, 234, 0.4) -18.38%,
   rgba(122, 90, 238, 0.4) 108.82%
 )`
      : "none"};

  &:hover {
   background: ${({ isSelected }) =>
     isSelected
       ? ` linear-gradient(
         180deg,
         rgba(81, 114, 234, 0.6) -18.38%,
         rgba(122, 90, 238, 0.4) 108.82%
       );
       `
       : `linear-gradient(
         180deg,
         rgba(81, 114, 234, 0.1) -18.38%,
         rgba(122, 90, 238, 0.1) 108.82%
       );`}
`;

const FontSizeVariantButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 25px;
`;

const FontSizeVariantButton = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ fontVariant }) => fontVariant};
  font-size: ${({ fontSizeVariant }) => fontSizeVariant}px;
  padding: 0 6px;
  height: 100%;
  border-radius: 6px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.1px;
  cursor: pointer;

  background: ${({ isSelected }) =>
    isSelected
      ? `linear-gradient(
   180deg,
   rgba(81, 114, 234, 0.4) -18.38%,
   rgba(122, 90, 238, 0.4) 108.82%
 )`
      : "none"};

  &:hover {
   background: ${({ isSelected }) =>
     isSelected
       ? ` linear-gradient(
         180deg,
         rgba(81, 114, 234, 0.6) -18.38%,
         rgba(122, 90, 238, 0.4) 108.82%
       );
       `
       : `linear-gradient(
         180deg,
         rgba(81, 114, 234, 0.1) -18.38%,
         rgba(122, 90, 238, 0.1) 108.82%
       );`}
`;

const FontSettingsBlock = () => {
  const { bibleFontSettings, setBibleFontSettings } = useAppContext();
  const [selectFontBlock, setSelectFontBlock] = useState(FONTS_VARIANTS[0]);

  const [exceptions, setExceptions] = useState(null);

  const onClickFontVariantButton = useCallback((font) => {
    setBibleFontSettings((prevState) => ({
      ...prevState,
      fontVariant: font.name,
    }));
  }, []);

  useEffect(() => {
    const currentFont = FONTS_VARIANTS.find(
      (font) => font.name === bibleFontSettings.fontVariant
    );

    if (currentFont) {
      setSelectFontBlock(currentFont);
    }
  }, [bibleFontSettings]);

  useEffect(() => {
    if (!selectFontBlock.sizes.includes(bibleFontSettings.fontSize)) {
      let currentIndex;

      if (bibleFontSettings.fontVariant === "Ysabeau Infant") {
        currentIndex = FONTS_VARIANTS[0].sizes.indexOf(
          bibleFontSettings.fontSize
        );
      } else {
        currentIndex = FONTS_VARIANTS[2].sizes.indexOf(
          bibleFontSettings.fontSize
        );
      }
      setExceptions(currentIndex);
    }
    return () => setExceptions(null);
  }, [selectFontBlock, bibleFontSettings]);

  return (
    <FontSettingsBlockContainer>
      <FontVariantButtonsContainer>
        {FONTS_VARIANTS.map((font, index) => (
          <FontVariantButton
            key={font.name}
            isSelected={bibleFontSettings.fontVariant === font.name}
            fontVariant={font.name}
            onClick={() => onClickFontVariantButton(font)}
          >
            Шрифт {index + 1}
          </FontVariantButton>
        ))}
      </FontVariantButtonsContainer>

      <FontSizeVariantButtonsContainer>
        {selectFontBlock.sizes.map((size, index) => (
          <FontSizeVariantButton
            key={size * index}
            fontVariant={selectFontBlock.name}
            fontSizeVariant={size}
            isSelected={
              size === bibleFontSettings.fontSize || index === exceptions
            }
            onClick={() =>
              setBibleFontSettings({
                fontVariant: selectFontBlock.name,
                fontSize: size,
              })
            }
          >
            Aa
          </FontSizeVariantButton>
        ))}
      </FontSizeVariantButtonsContainer>
    </FontSettingsBlockContainer>
  );
};

export default memo(FontSettingsBlock);
