import React, { memo, useCallback } from "react";
import styled from "styled-components";
import VerticalLine from "../../../../common/assets/icons/vertical_line";
import RemoveIcon from "../../../../common/assets/icons/remove_icon";
import { useBiblePageContext } from "../../bible_page_context";

const TranslationHeaderMobileContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 14px;
  height: 48px;
  width: 100%;
`;

const BibleAbbreviation = styled.div`
  text-transform: uppercase;
  color: #f8d254;
  font-size: 14px;
  font-weight: 600;
  line-height: 122%;
  letter-spacing: 0.4px;
  margin-right: 5px;
`;

const VerticalLineStyled = styled(VerticalLine)`
  width: 1px;
  margin-right: 5px;
`;

const TranslationName = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.14px;
  color: #828282;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 76%;
  flex: 1;
`;

const CloseTranslationButton = styled.div`
  padding: 8px 16px 6px 4px;
`;

const RemoveIconStyled = styled(RemoveIcon)`
  width: 22px;
  height: 22px;
`;

const TranslationHeaderMobile = ({
  currentTranslation,
  onClickCloseButton,
}) => {
  return (
    <TranslationHeaderMobileContainer>
      <BibleAbbreviation>{currentTranslation?.bible_code}</BibleAbbreviation>
      <VerticalLineStyled height="20px" />
      <TranslationName>{currentTranslation?.bible_name}</TranslationName>

      <CloseTranslationButton onClick={onClickCloseButton}>
        <RemoveIconStyled color="#383838" />
      </CloseTranslationButton>
    </TranslationHeaderMobileContainer>
  );
};

export default memo(TranslationHeaderMobile);
