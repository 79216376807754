import React from "react";

const VerticalLine = ({ className, height = "38", color = "#CACACA" }) => {
  return (
    <svg
      width="2"
      height={height}
      viewBox="0 0 2 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        id="Line 14"
        d="M1 1L1 37"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default VerticalLine;
