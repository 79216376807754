import React, { memo, useCallback, useState } from "react";
import styled from "styled-components";
import VerticalLine from "../../assets/icons/vertical_line";

const UnsavedChangesModalWindowWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 9999;
`;

const UnsavedChangesModalWindowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 34px;
  padding: 42px;
  border-radius: 25px;
  background: #eaeaea;
  color: #1e1e1e;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 145%; /* 23.2px */
  letter-spacing: 0.24px;
  user-select: none;
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 420px;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #5172ea;
  cursor: pointer;
`;

const ButtonStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.24px;
  transition: 0.2s;
  user-select: none;

  &:hover {
    font-size: 17px;
    color: #5172ea;
    transition: 0.2s;
    width: 33%;
  }
`;

const SaveButton = styled(ButtonStyles)`
  color: rgba(81, 114, 234, 0.4);
`;

const NotSaveButton = styled(SaveButton)``;

const CancelButton = styled(ButtonStyles)`
  color: ${({ isHoverOnSave }) =>
    isHoverOnSave ? "rgba(81, 114, 234, 0.4)" : "#5172ea"};
`;

const VerticalLineStyled = styled(VerticalLine)`
  height: 22px;
`;

const UnsavedChangesModalWindow = ({
  setOpen,
  onSaveSettingsButton,
  onClickNotSaveButton,
}) => {
  const [isHoverOnSave, setIsHoverOnSave] = useState(false);

  const toggleHoverOnSaveButton = useCallback(() => {
    setIsHoverOnSave((prevState) => !prevState);
  }, []);

  const handleClose = useCallback((event) => {
    event.stopPropagation();
    setOpen(false);
  }, []);

  return (
    <UnsavedChangesModalWindowWrapper
      onMouseDown={(e) => e.stopPropagation()}
      onClick={handleClose}
    >
      <UnsavedChangesModalWindowContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        У вас есть несохраненные изменения!
        <ModalButtonsContainer>
          <SaveButton
            onMouseEnter={toggleHoverOnSaveButton}
            onMouseLeave={toggleHoverOnSaveButton}
            onClick={onSaveSettingsButton}
          >
            Сохранить
          </SaveButton>
          <VerticalLineStyled color={"#5172EA"} />
          <NotSaveButton
            onMouseEnter={toggleHoverOnSaveButton}
            onMouseLeave={toggleHoverOnSaveButton}
            onClick={onClickNotSaveButton}
          >
            Не сохранять
          </NotSaveButton>
          <VerticalLineStyled color={"#5172EA"} />
          <CancelButton isHoverOnSave={isHoverOnSave} onClick={handleClose}>
            Отмена
          </CancelButton>
        </ModalButtonsContainer>
      </UnsavedChangesModalWindowContainer>
    </UnsavedChangesModalWindowWrapper>
  );
};

export default memo(UnsavedChangesModalWindow);
