import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { useAppContext } from "../../../../app_context";
import { useBiblePageContext } from "../../bible_page_context";

const BibleVersesHeightsContext = createContext();

export const useBibleVersesHeightsState = () => {
  const isSyncing = useRef(false);
  const [searchParams] = useSearchParams();
  const { bibleFontSettings } = useAppContext();
  const { showStrongsCode } = useBiblePageContext();

  const [verseHeightsFirstTransl, setVerseHeightsFirstTransl] = useState([]);
  const [verseHeightsSecondTransl, setVerseHeightsSecondTransl] = useState([]);
  const [mergedHeights, setMergedHeights] = useState([]);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollContainerTarget, setScrollContainerTarget] = useState(0);

  const bibleId = searchParams.get("bibleId");
  const bibleId2 = searchParams.get("2bibleId");
  const bookId = searchParams.get("bookId");
  const chapterId = searchParams.get("chapterId");

  const getMaxArray = (array1, array2) => {
    return array1.map((value, index) =>
      Math.max(value, array2[index] ? array2[index] : 0)
    );
  };

  useEffect(() => {
    if (verseHeightsFirstTransl.length) {
      const maxHeights = getMaxArray(
        verseHeightsFirstTransl,
        verseHeightsSecondTransl
      );
      setMergedHeights(maxHeights);
    }
  }, [verseHeightsFirstTransl, verseHeightsSecondTransl]);

  useEffect(() => {
    setMergedHeights([]);
    setVerseHeightsFirstTransl([]);
    setVerseHeightsSecondTransl([]);
  }, [
    bibleId,
    bibleId2,
    bookId,
    chapterId,
    showStrongsCode,
    bibleFontSettings,
  ]);

  const handleScroll = useCallback(
    (scrollTopValue) => {
      if (!isSyncing.current) {
        isSyncing.current = true;
        setScrollTop(scrollTopValue);
      } else {
        isSyncing.current = false;
      }
    },
    [isSyncing.current]
  );

  return {
    scrollTop,
    handleScroll,
    setVerseHeightsFirstTransl,
    setVerseHeightsSecondTransl,
    mergedHeights,
    verseHeightsFirstTransl,
    verseHeightsSecondTransl,
    scrollContainerTarget,
    setScrollContainerTarget,
  };
};

export const BibleVersesHeightsProvider = ({ children }) => {
  const {
    scrollTop,
    handleScroll,
    setVerseHeightsFirstTransl,
    setVerseHeightsSecondTransl,
    mergedHeights,
    verseHeightsFirstTransl,
    verseHeightsSecondTransl,
    scrollContainerTarget,
    setScrollContainerTarget,
  } = useBibleVersesHeightsState();

  return (
    <BibleVersesHeightsContext.Provider
      value={{
        scrollTop,
        handleScroll,
        setVerseHeightsFirstTransl,
        setVerseHeightsSecondTransl,
        mergedHeights,
        verseHeightsFirstTransl,
        verseHeightsSecondTransl,
        scrollContainerTarget,
        setScrollContainerTarget,
      }}
    >
      {children}
    </BibleVersesHeightsContext.Provider>
  );
};

export const useBibleVersesHeightsContext = () => {
  return useContext(BibleVersesHeightsContext);
};
