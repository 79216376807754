import React from "react";

const SelectedCheckboxLarge = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={className}
    >
      <rect
        x="0.5"
        y="0.5"
        width="21"
        height="21"
        rx="3.5"
        stroke="url(#paint0_linear_4324_5294)"
      />
      <rect
        x="4"
        y="4"
        width="14"
        height="14"
        rx="2"
        fill="url(#paint1_linear_4324_5294)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4324_5294"
          x1="10.4683"
          y1="-4.04412"
          x2="10.4683"
          y2="23.9412"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4324_5294"
          x1="10.6616"
          y1="1.42647"
          x2="10.6616"
          y2="19.2353"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5172EA" />
          <stop offset="1" stopColor="#7A5AEE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SelectedCheckboxLarge;
