import React, { memo, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import useIsMobile from "../../../../../common/hooks/use_is_mobile";
import { useBiblePageContext } from "../../../bible_page_context";

const BookChapterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: white;
  cursor: pointer;
  user-select: none;
  border-radius: 5px;
  transition: 0.2s;
  font-size: 16px;
  line-height: 150%;

  background: ${({ isActive }) =>
    isActive
      ? "linear-gradient(180deg, #5172EA -18.38%, #7A5AEE 108.82%)"
      : "#D9D9D9"};

  ${({ isActive }) =>
    !isActive &&
    `
      &:hover {
         background: #bfbebe;
      }
    `}

  @media (max-width: 744px) {
    width: 40px;
    height: 40px;
    ${({ isActive }) => !isActive && "background: #CACACA"};
  }
`;

const ChapterNavigationItem = ({ chapterNum, book }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { setActiveScreenMobile } = useBiblePageContext();

  const [selectedChapter, setSelectedChapter] = useState(false);

  const isMobile = useIsMobile(744);

  useEffect(() => {
    const isSelected =
      chapterNum === +searchParams.get("chapterId") &&
      book.book_code === searchParams.get("bookId");
    setSelectedChapter(isSelected);
  }, [searchParams.get("chapterId"), searchParams.get("bookId")]);

  const handleSetParams = useCallback(() => {
    if (!chapterNum || !book?.bible_code || !book?.book_code) {
      console.error("Не указаны необходимые параметры для изменения роута");
      return;
    }

    const updatedParams = new URLSearchParams(searchParams);

    updatedParams.set("bibleId", book.bible_code);
    updatedParams.set("bookId", book.book_code);
    updatedParams.set("chapterId", chapterNum);
    updatedParams.delete("selectVerses");
    updatedParams.delete("selectBible");

    const secondBibleId = searchParams.get("2bibleId");
    if (secondBibleId) {
      updatedParams.set("2bibleId", secondBibleId);
    }

    setSearchParams(updatedParams);

    if (isMobile) {
      setActiveScreenMobile("bible_chapter");
    }
  }, [chapterNum, book, searchParams, setSearchParams, isMobile]);

  return (
    <BookChapterButton isActive={selectedChapter} onClick={handleSetParams}>
      {chapterNum}
    </BookChapterButton>
  );
};

export default memo(ChapterNavigationItem);
